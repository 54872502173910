.products-grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: ac(-50px, -15px);
  margin-top: ac(-50px, -15px);
  margin-bottom: 30px;

  .not-found {
    font-size: ac(50px, 24px);
    line-height: ac(70px, 36px);
    font-weight: 600;
    white-space: nowrap;
  }

  .products {
    &__pagination {
      text-align: left;
      left: 5px;
      bottom: 5px;

      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        border: 2px solid var(--color-red);
        background: transparent;
        transition: .3s ease;

        &:hover {
          background: var(--color-red);
        }

        &-active {
          border-color: var(--color-red);
          background: var(--color-red);
        }
      }
    }

    &__card {
      width: calc(100% / 4 - ac(50px, 15px));
      margin-top: ac(50px, 15px);
      margin-right: ac(50px, 15px);

      .swiper-slide {
        &:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background: var(--color-sky-blue);
          background-blend-mode: multiply;
          mix-blend-mode: multiply;
          z-index: 10;
        }
      }

      @mixin media 1025 {
        width: calc(100% / 3 - ac(50px, 15px));
      }

      @mixin media 769 {
        width: calc(100% / 2 - ac(50px, 15px));
      }

      @mixin media 340 {
        width: calc(100% - ac(50px, 15px));
      }
    }
  }
}

.filters-btn-shop {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  font-size: 20px;
  border: 1px solid var(--color-grey);
}

.products-search {
  padding-top: ac(100px, 50px);
  padding-bottom: 0;

  @mixin media 568 {
    padding-top: 90px;
  }

  &__container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;

    @mixin media 1680 {
      justify-content: center;
    }
  }

  &__sort {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 20px;
    width: 100%;
    max-width: 280px;
    margin-left: auto;
    position: relative;
    z-index: 3;

    &>span {
      margin-right: 15px;
      padding-bottom: 4px;
      flex: 0 0 auto;
    }

    .nice-select {
      width: 100%;
    }

    @mixin media 769 {
      margin-bottom: -41px;

      &>span {
        padding-bottom: 0px;
      }

      .nice-select {
        height: 40px;

        &:before {
          width: 32px;
          height: 32px;
        }
      }
    }

    @mixin media 568 {
      margin-bottom: -95px;
      max-width: unset;
    }
  }

  .products-sidebar {
    height: 100%;
    width: 19%;

    @mixin media 1680 {
      width: 23.08%;
      max-width: 310px;
    }

    @mixin media 1025 {
      width: 30%;
    }

    @mixin media 769 {
      height: 100vh;
      padding-top: 0;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(17, 17, 17, 0.5);
      z-index: -10;
      transition: all 0.3s ease;
      opacity: 0;
      max-width: 100%;

      .products-sidebar__container {
        transform: translateX(-110%);
        transition: all 0.3s ease;
      }

      &.active {
        z-index: 10000;
        opacity: 1;

        .products-sidebar__container {
          transform: translateX(0%);
          transition: all 0.3s ease;
          overflow-x: hidden;
        }
      }
    }

    .products-btn-back {
      margin-top: 10px;
    }

    &__container {
      /*height: calc(100vh - 150px);*/
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: transparent transparent;
      padding-bottom: 50px;
      padding-right: 20px;
      border-right: 1px solid var(--color-grey);

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: transparent;
        border-radius: 2px;
        cursor: pointer;
        transition: all 0.3s ease;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: transparent;
        transition: all 0.3s ease;
      }

      &:hover {
        scrollbar-width: thin;
        scrollbar-color: var(--color-grey) transparent;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--color-grey);
          cursor: pointer;
          transition: all 0.3s ease;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: var(--color-liver);
          transition: all 0.3s ease;
        }
      }

      .top-block {
        display: none;
      }

      @mixin media 769 {
        height: 100%;
        width: 85%;
        min-width: 315px;
        padding-left: 16px;
        background: var(--color-white);
        padding-top: 16px;
        padding-right: 16px;
        /*padding-bottom: 100px;*/
        padding-bottom: 0;

        .top-block {
          display: flex;
          width: 100%;
          padding-bottom: 16px;
          border-bottom: 1px solid var(--color-grey);
          align-items: center;
          justify-content: flex-start;
        }
      }

      .show-search-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-top: 30px;

        .btn {
          height: 44px;

          &:hover {
            border: 1px solid var(--color-blue);
          }

          &--form {
            flex-grow: 1;
            margin-right: ac(20px, 10px);
          }

          &--delete {
            min-width: auto;
            width: 44px;
            padding: 5px;
            font-size: 20px;
            border: 1px solid var(--color-grey);
          }
        }

        @mixin media 769 {
          background: var(--color-white);
          padding-bottom: 30px;
          padding-right: 16px;
          padding-left: 16px;
          margin-left: -16px;
          width: calc(100% + 32px);
        }
      }
    }

    &__elem {
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--color-grey);

      &:not(:first-child) {
        margin-top: ac(30px, 20px);
      }

      &--key-words {
        margin-top: ac(30px, 20px) !important;
      }

      h5 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
      }

      input {
        width: 100%;
        border: 1px solid var(--color-grey);
        padding-left: 10px;
        padding-right: 20px;
        border-radius: 1px;
        height: 40px;
        transition: all 0.3s ease;
        font-weight: 400;
        font-size: 15px;
        line-height: 130.5%;

        &::placeholder {
          opacity: 1;
          color: var(--color-black);
          font-weight: 400;
          font-size: 15px;
          line-height: 130.5%;
        }

        &:focus {
          border: 1px solid var(--color-blue);
          transition: all 0.3s ease;
        }
      }

      .keywords-block {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        &__btn {
          padding: 10px;
          background: var(--color-porcelain);
          border: none;
          color: var(--color-black);
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: var(--font-main);
          font-weight: 400 !important;
          margin-right: ac(15px, 10px);
          margin-top: 10px;
          border-radius: 4px;
          transition: background-color 0.3s ease;
          line-height: 1.2;

          &:hover {
            transition: background-color 0.3s ease;
            background: var(--color-grey);
          }

          .icon-close {
            order: 2;
            transition: all 0.3s ease;
            font-family: "icomoon" !important;
            margin-left: 10px;
            font-size: 12px;

            &:hover {
              transform: rotate(90deg);
            }
          }
        }
      }

      /* TODO: Comment out double range with tooltips */
      /*.double-range-container {
        height: 62px;
      }*/

      .double-range {
        /* TODO: Comment out double range with tooltips */
        /*margin-bottom: 80px;*/

        padding-right: 15px;
        height: 6px;
        border-radius: 12px;
        border: none;
        box-shadow: none;
        background: var(--color-grey);

        .noUi-connect {
          background: var(--color-blue);
          margin-right: -4px;
        }

        .noUi-handle {
          background: var(--color-blue);
          width: 18px;
          height: 18px;
          border: 2px solid var(--color-white);
          cursor: pointer;
          border-radius: 50%;
          box-shadow: none;

          &:before,
          &:after {
            content: none;
          }
        }
      }

      .om-sliderrange {
        height: auto !important;
      }

      ul {
        scrollbar-width: thin;
        scrollbar-color: var(--color-blue) var(--color-grey);
        width: 98.5%;

        &::-webkit-scrollbar {
          width: 4px;
        }

        &::-webkit-scrollbar-track {
          background: var(--color-grey);
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--color-blue);
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: var(--color-light-blue);
        }

        li {
          padding-left: 0;
          margin-bottom: 22px;
          position: relative;
          padding-right: 22px;
          margin-right: 4px;

          .checkbox {
            margin-bottom: 0;
          }

          &:before {
            content: none;
          }

          .content-child-toggle {
            font-size: ac(18px, 14px);
            position: absolute;
            right: ac(4px, 2px);
            top: 0;
            height: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            z-index: 1;
            transition: transform .3s ease, color .3s ease;
            transform: scale(-1);

            &:hover {
              color: var(--blue);
            }

            &.active {
              transform: scale(1);
            }


          }

          label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;
            font-size: ac(20px, 16px);
            transition: all 0.4s ease;
            color: var(--color-black);

            p {
              transition: all 0.4s ease;
              font-size: inherit;
              color: inherit;
              position: relative;
              padding-left: 28px;
              margin-left: 0;
              display: inline-flex;

              &:before {
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                content: "";
                width: 20px;
                height: 20px;
                border-radius: 4px;
                border: 1px solid var(--color-grey);
                transition: all 0.4s ease;
                background-image: url("../images/icons/check-white.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 99% 99%;
              }
            }

            input {
              display: none;
            }

            &:hover {
              color: var(--color-blue);
              transition: all 0.4s ease;

              p {
                &:before {
                  border: 1px solid var(--color-blue);
                }
              }
            }

            input:checked ~ p:before {
              background-color: var(--color-blue);
              border: none;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          ul {
            max-height: 1000px !important;
            transition: padding .3s ease;

            li {
              padding-right: 0;
              margin-bottom: 10px;
            }

            &.content-child-list {
              overflow: hidden;
              height: 0;
              transition: transform .3s ease, height .3s ease, opacity .25s ease;
              transform: translateX(-10px);
              opacity: 0;

              &.active {
                padding-top: 10px;
                height: auto;
                opacity: 1;
                transform: translateX(20px);

              }

              .label {
                font-size: ac(18px, 14px);

                p {
                  &:before {
                    width: 18px;
                    height: 18px;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__accordion {
      .accordion-trigger {
        width: 100%;
        cursor: pointer;
        position: relative;
        font-family: var(--font-main) !important;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:before {
          font-family: "icomoon" !important;
          font-weight: 400;
          order: 2;
          transition: all 0.4s ease;
        }

        &.active {
          &:before {
            transform: scale(-1, -1);
          }
        }
      }

      .accordion-content {
        overflow: hidden;
        max-height: 0;
        transition: all 0.4s ease;
        padding-top: 0;

        @mixin media 769 {
          padding-right: 5px;
        }

        &.active {
          overflow-y: auto;
          max-height: 260px;
          margin-top: ac(25px, 15px);
          padding-bottom: 30px;
          &.double-range-content {
            padding-bottom: 0;
            overflow: visible;
          }
        }
      }
    }
  }

  .phone-filters-btn {
    display: none;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
    width: min-content;
    cursor: pointer;

    .filters-btn {
      font-size: 20px;
      min-width: auto;
      padding: 5px;
      width: 40px;
      border: 1px solid var(--color-grey);
      height: 40px;
      margin-right: 10px;
    }

    p {
      transition: color 0.3s ease;
      font-size: 15px;
    }

    &:hover {
      p {
        text-decoration: underline;
        color: var(--color-blue);
      }
    }

    @mixin media 769 {
      display: flex;
    }
    @mixin media 568 {
      margin-bottom: 90px;
    }
  }

  .products-wrapper {
    padding-top: 0;
    padding-left: ac(62px, 15px);
    padding-right: ac(62px, 0px);
    position: relative;
    z-index: 1;
    width: 82.7%;
    margin-left: auto;

    @mixin media 1680 {
      width: 76.92%;
      margin-left: 0;
    }

    @mixin media 1450 {
      padding-right: 0;
    }

    @mixin media 1025 {
      width: 70%;
    }

    @mixin media 769 {
      width: 100%;
      padding-left: 0;
      border-left: none;
    }

    .filters-menu {
      position: relative;
      width: 246px;

      &__btn {
        position: relative;
        border: 1px solid var(--color-grey);
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 8px;
        overflow: hidden;
        height: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.4s ease;
        cursor: pointer;

        .btn-shop {
          position: absolute;
          z-index: 10;
          top: 0;
          left: 0;
          bottom: 0;
          height: 100%;
          width: 40px;
          padding: 5px;
          border: none;
          border-radius: 0;
          border-right: 1px solid var(--color-grey);
          display: none;
          font-size: 20px;

          @mixin media 769 {
            display: flex;
          }
        }

        @mixin media 769 {
          padding-left: 51px;
        }
      }

      &__active {
        display: flex;
        align-items: center;
        width: 100%;
        font-family: var(--font-main) !important;
        transition: all 0.3s ease;

        &:before {
          order: 2;
          margin-left: auto;
          font-family: "icomoon" !important;
          transition: all 0.3s ease;
        }

        &:hover {
          color: var(--color-black);
        }

        &.active {
          &:before {
            transform: scale(-1, -1);
          }
        }
      }

      ul {
        display: none;

        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 4px;
        background: var(--color-white);
        border-radius: 4px;
        border: 1px solid var(--color-grey);
        position: absolute;
        z-index: 10;
        bottom: -2px;
        transform: translateY(100%);
        left: 0;
        right: 0;
        width: 100%;

        &.active {
          display: flex;
        }

        li {
          padding: 6px;
          width: 100%;
          cursor: pointer;
          transition: all 0.3s ease;

          &:before {
            content: none;
          }

          &.active {
            background: var(--color-grey);
          }

          &:hover {
            transition: all 0.3s ease;
            color: var(--color-blue);
            padding-left: 12px;
          }
        }
      }

      @mixin media 421 {
        width: 100%;
      }
    }
  }

  .navigation-block {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .num-pages {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @mixin media 769 {
        .num-btn {
          &:nth-child(n + 4) {
            display: none;
          }
        }
      }
    }

    .arrow {
      cursor: pointer;
    }

    .arrow,
    .num-btn {
      margin: 0 5px;
      font-weight: 400;
      font-size: 16px;
      width: 44px;
      height: 44px;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--color-grey);

      &:before {
        width: 12px;
        height: 12px;
        border-bottom: 2px solid var(--color-black);
        border-right: 2px solid var(--color-black);
      }

      &:hover {
        border: 1px solid var(--color-blue);
        background: var(--color-blue);
        color: var(--color-white);

        &:before {
          border-bottom: 2px solid var(--color-white);
          border-right: 2px solid var(--color-white);
        }
      }

      &.active {
        border: 1px solid var(--color-blue);

        &:hover {
          background: transparent;
          color: var(--color-black);
        }
      }
    }
  }
}

.noUi-tooltip {
  /*transform: translate(-50%, 100%);
  left: 50%;
  bottom: -10px;*/
  position: relative;
  display: block !important;
  background: var(--color-porcelain);
  font-size: 15px;
  font-weight: 400;
  font-family: var(--font-main);
  color: var(--color-black);
  line-height: 1.2;
  padding: 11px 12px;
  border: none;
  border-radius: 4px;
  &.hidden {
    display: none !important;
  }
}
