.credit-card {
	margin-top: auto;
	padding: ac(30px, 15px);
	border: 1px solid var(--color-grey);

	&__logo-box {
		margin-top: ac(40px, 20px);
		display: flex;
		flex-wrap: wrap;
		margin-right: -10px;
		margin-bottom: -10px;

		&.known-bank {
			.credit-card__logo {
				filter: grayscale(1);
			}
		}
	}

	&__logo {
		width: 50px;
		height: 31px;
		margin-right: 10px;
		margin-bottom: 10px;

		&.active {
			filter: grayscale(0) !important;
		}
	}

	&__descr {
		margin-top: 21px;
		font-size: 15px;
		line-height: 131%;
	}

	&__form {
		margin: 24px 0 0;

		label {
			width: 100%;
		}
	}

	&__privacy {
		margin-right: 0px;
		margin-top: ac(30px, 20px);
		font-size: 15px;
		line-height: 146%;

		a {
			color: var(--color-blue);

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&__checkbox {
		position: relative;
  		display: flex;
  		flex-direction: row !important;
		align-items: center;
		margin-top: 20px !important;
		cursor: pointer;

		input {
			width: 0;
			height: 0;
			visibility: hidden;
			padding: 0 !important;

			&:checked ~ span {
				border-color: var(--color-blue);
				&::before {
					opacity: 1;
				}
			}
		}

		span {
			flex: 0 0 auto;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 3px;
			width: 15px;
			height: 15px;
			border: 2px solid var(--color-black);
			background-color: var(--color-white);
			cursor: pointer;
			transition: 0.3s ease;

			&::before {
				content: "";
				width: 100%;
				height: 80%;
				border-bottom: 2px solid var(--color-blue);
				border-left: 2px solid var(--color-blue);
				transform: rotate(-45deg);
				opacity: 0;
				transition: .3s ease;
			}

			&:hover {
				border-color: var(--color-blue);
			}
		}

		p {
			margin-bottom: 0 !important;
			margin-left: 12px;
			font-size: 14px;
			line-height: 30px;
		}
	}

	&__btn-box {
		margin-top: ac(42px, 25px);

		.btn {
			font-size: 16px;
		}
	}

	@mixin media 568 {
		&__title {
			text-align: center;
		}
		
		&__btn-box {
			.btn {
				width: 100%;
			}
		}
	}
}