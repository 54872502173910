.first-slider {
    margin-top: 21px;

    &__slider {
        width: 100%;
        height: ac(600px, 100px, 375, 1920);
        position: relative;
    }

    &__pagination {
        bottom: ac(37px, 10px) !important;

        .swiper-pagination-bullet {
            margin: 0 5px !important;
            width: 40px;
            height: 6px;
            background-color: var(--color-white);
            opacity: 0.35;
            border-radius: 0;

            &-active {
                opacity: 1;
            }

        }
    }

    &__prev, &__next {
        position: absolute;
        bottom: 50%;
        transform: translateY(50%);
        z-index: 11;
    }

    &__prev {
        left: ac(75px, 10px);
        transform: translateY(50%) rotate(180deg);
    }

    &__next {
        right: ac(75px, 10px);
    }

    @mixin media 568 {
        &__pagination  {
            display: none;
        }

        &__prev, &__next {
            width: 15px;
            background: transparent;

            &:before {
                border-color: var(--color-white);
            }

            &:hover {
                background: transparent;
                &:before {
                    border-color: var(--color-blue);
                }
            }
        }
    }
}