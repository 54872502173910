.header {
  z-index: 2;

  &--contact-us {
    .header__wrapper {
      padding: ac(32px, 12px) 0px ac(52px, 12px);
    }
  }

  &--content {
    .header__wrapper {
      padding: ac(29px, 12px) 0px;
    }
  }

  &__layout {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;

    &.active {
      opacity: 0.5;
      visibility: visible;
    }
  }

  &__top {
    background-color: var(--color-blue);

    .header {
      &__link {
        position: relative;
        margin-left: ac(39px, 20px);

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: var(--color-white);
          bottom: 5px;
          left: 0;
          transform-origin: right;
          transform: scaleX(0);
          transition: transform 0.3s ease-in-out;
        }

        &:hover {
          &::before {
            transform-origin: left;
            transform: scaleX(1);
          }
        }
      }

      &__link,
      &__text {
        color: var(--color-white);
        font-size: 14px;
        line-height: 32px;
      }
    }
  }

  &__text {
    margin-right: auto;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    padding: ac(30px, 12px) 0;

    .header__link {
      margin-right: ac(47px, 20px);
      flex: 0 0 auto;
      font-weight: 600;

      &:hover {
        color: var(--color-blue);
      }

      &:nth-of-type(2) {
        margin-left: ac(75px, 20px);
      }
    }

    &.active {
      .header {
        &__sub-menu {
          max-height: min(80vh, 1000px);
          overflow-y: auto;
          overflow-x: hidden;
          background: var(--color-white);
          box-shadow: 0 15px 15px rgb(0 0 0 / 50%);
          padding: 20px;
          opacity: 1;
          visibility: visible;
          @mixin scrollbar;
        }

        &__menu-btn {
          color: var(--color-blue);

          .header__arrow {
            transform: rotate(180deg);

            &::before {
              border-color: var(--color-blue);
            }
          }
        }
      }
    }
  }

  &__logo {
    margin-right: ac(64px, 20px);
    width: ac(151px, 120px);
    height: ac(44px, 34px);
    flex: 0 0 auto;
    background: transparent;
    color: unset;

    img {
      object-fit: contain;
    }
  }

  &__menu-btn {
    margin-right: 20px;
    display: flex;
    align-items: center;
    font-size: ac(16px, 14px);
    font-weight: 500;
    cursor: pointer;
    transition: color 0.3s ease;
    z-index: 3;

    &:hover {
      color: var(--color-blue);

      .header__arrow {
        &::before {
          border-color: var(--color-blue);
        }
      }
    }
  }

  &__arrow {
    position: relative;
    margin-left: 5px;
    width: 10px;
    height: 10px;
    transition: transform 0.3s ease;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 5px;
      height: 5px;
      border-bottom: 1px solid var(--color-black);
      border-right: 1px solid var(--color-black);
      transform: translate(-50%, -75%) rotate(45deg);
      transition: border-color 0.3s ease;
    }
  }

  &__search {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 700px;
    border: 1px solid var(--color-grey);
  }

  &__input {
    width: 100%;
    padding: 12px ac(60px, 30px) 12px ac(28px, 15px);
    color: var(--color-black);
    font-size: 16px;

    &::placeholder {
      color: var(--color-light-grey);
    }
  }

  &__loupe {
    position: absolute;
    right: ac(30px, 10px);
    top: 50%;
    transform: translateY(-50%);

    width: 18px;
    height: 18px;
    cursor: pointer;

    img {
      object-fit: contain;
    }

    &:focus, &:active {
      outline: none;
    }
  }

  &__cart {
    position: relative;
    width: 25px;
    height: 25px;
    cursor: pointer;
    flex: 0 0 auto;

    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.44' height='23.553' fill='%23000'%3E%3Cpath d='M19.749 16.887a2.264 2.264 0 0 0 2.165-1.681l2.507-9.337a.57.57 0 0 0-.4-.7.67.67 0 0 0-.144-.021H5.028L3.746.42A.564.564 0 0 0 3.203 0H0v1.133h2.756L4 5.762a.393.393 0 0 0 0 .107l2.554 9.364a1.871 1.871 0 0 0 .069.229l.862 3.139a2.628 2.628 0 1 0 3.539 1.068 5.76 5.76 0 0 0-.154-.255h6.688a2.628 2.628 0 1 0 4.086-.266 2.553 2.553 0 0 0-1.9-.867H8.614l-.4-1.463a2.158 2.158 0 0 0 .521.069Zm-.037 2.527a1.511 1.511 0 1 1-1.512 1.511 1.51 1.51 0 0 1 1.512-1.511Zm-11 0A1.511 1.511 0 1 1 7.2 20.925a1.509 1.509 0 0 1 1.515-1.511ZM7.7 15.035l-1.272-4.65L5.3 6.299h17.817l-2.3 8.651a1.137 1.137 0 0 1-1.08.851H8.725a1.148 1.148 0 0 1-1.025-.766Z'/%3E%3C/svg%3E");
    transition: 0.3s ease;

    &:hover {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24.44' height='23.553' fill='%230060ac'%3E%3Cpath d='M19.749 16.887a2.264 2.264 0 0 0 2.165-1.681l2.507-9.337a.57.57 0 0 0-.4-.7.67.67 0 0 0-.144-.021H5.028L3.746.42A.564.564 0 0 0 3.203 0H0v1.133h2.756L4 5.762a.393.393 0 0 0 0 .107l2.554 9.364a1.871 1.871 0 0 0 .069.229l.862 3.139a2.628 2.628 0 1 0 3.539 1.068 5.76 5.76 0 0 0-.154-.255h6.688a2.628 2.628 0 1 0 4.086-.266 2.553 2.553 0 0 0-1.9-.867H8.614l-.4-1.463a2.158 2.158 0 0 0 .521.069Zm-.037 2.527a1.511 1.511 0 1 1-1.512 1.511 1.51 1.51 0 0 1 1.512-1.511Zm-11 0A1.511 1.511 0 1 1 7.2 20.925a1.509 1.509 0 0 1 1.515-1.511ZM7.7 15.035l-1.272-4.65L5.3 6.299h17.817l-2.3 8.651a1.137 1.137 0 0 1-1.08.851H8.725a1.148 1.148 0 0 1-1.025-.766Z'/%3E%3C/svg%3E");
    }
  }

  &__counter {
    position: absolute;
    right: -1px;
    top: -2px;
    background-color: var(--color-red);
    padding: 0px 3px;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 10px;
    line-height: 12px;
    color: var(--color-white);
  }

  &__sub-menu {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: ac(46px, 20px);
    padding-bottom: ac(33px, 15px);
    transform: translateY(100%);
    z-index: 10;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease;

    &::before {
      opacity: 0;
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: 100%;
      box-shadow: 0 15px 15px rgba(0, 0, 0, 0.5);
      background-color: var(--color-white);
      z-index: -1;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--color-grey);
    }

    .header {
      &__link {
        font-size: 16px;
        line-height: 30px;
        font-weight: 400;
        margin-right: 0;

        &:not(:last-child) {
          margin-bottom: 5px;
        }

        &:nth-of-type(2) {
          margin-left: 0;
        }
      }
    }
  }

  &__content, &__navbar {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-bottom: 20px;
    overflow: auto hidden;

    &::-webkit-scrollbar {
      background-color: var(--color-sky-blue);
      height: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-black);
    }
  }

  &__navbar {
    .header__link {
      font-size: 16px;
      line-height: 30px;
      font-weight: 400;
      margin-right: 0;

      &:not(:last-child) {
        margin-bottom: 5px;
      }

      &:nth-of-type(2) {
        margin-left: 0;
      }
    }
  }

  &__title {
    margin-bottom: 11px;
    font-size: 18px;
    line-height: 25px;
    font-weight: 600;

    &:only-child {
      &::after {
        display: none;
      }
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 0 auto;
    width: ac(220px, 210px);

    &:not(:last-child) {
      margin-right: ac(40px, 10px);
    }
  }

  &__link-box {
    width: 100%;
  }

  .simplebar-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .simplebar-vertical {
    width: 8px;
    background-color: var(--color-white);
  }

  .simplebar-scrollbar {
    background-color: var(--color-black);
  }

  &__burger-menu {
    align-items: center;
    margin-right: ac(20px, 15px);
  }

  &__burger {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease;
    z-index: 2;

    &>span {
      color: var(--color-black);
      transition: 0.3s ease;
      user-select: none;
      font-size: min(max(calc(12.66667px + .17361vw),14px),16px);
      font-weight: 500;
    }

    &:hover {
      &>span {
        color: var(--color-blue);
      }
      .header__burger-icon {
        span {
          background-color: var(--color-blue);
        }

      }
    }

    &.active {
      .header__burger-icon {
        span {
          &:first-child {
            transform: translate(0px, 7px) rotate(45deg);
          }

          &:last-child {
            transform: translate(0px, -7px) rotate(-45deg);
          }

          &:nth-child(2) {
            width: 0%;
          }
        }
      }
    }
  }

  &__burger-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 30px;
    height: 16px;
    margin-left: 5px;

    span {
      width: 75%;
      height: 2px;
      background-color: var(--color-black);
      border-radius: 5px;
      transition: 0.3s ease;
    }
  }

  &__navbar-box {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: ac(46px, 20px);
    padding-bottom: ac(33px, 15px);
    transform: translateY(100%);
    z-index: 10;
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease;

    .header__link{
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    &.active {
      max-height: min(80vh, 1000px);
      overflow-y: auto;
      overflow-x: hidden;
      background: var(--color-white);
      box-shadow: 0 15px 15px rgb(0 0 0 / 50%);
      padding: 20px;
      opacity: 1;
      visibility: visible;

      @mixin scrollbar;
    }
  }

  @media (min-width: 901px) {
    .header__col {
      width: auto;

      &:not(:last-child) {
        margin-right: ac(80px, 10px);
      }
    }

    .header__link-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @mixin media 900 {
    &__menu-btn {
      margin: 0;
    }
    &__top {
      .header__text {
        margin-left: 20px;
        width: 100%;
        text-align: center;
      }
    }

    &__row {
      margin-left: -20px;
      justify-content: center;
    }

    &__wrapper {
      flex-wrap: wrap;
      justify-content: space-between;

      .header__link,
      .header__cart {
        margin: 10px 20px 0;
      }
    }

    &__navbar-box {
      bottom: 0px;
    }

    &__search {
      margin-top: 20px;
      max-width: unset;
    }

    &__input {
      padding-right: 50px;
      padding-left: 20px;
    }

    &__loupe {
      right: 20px;
    }

    &__sub-menu, &__navbar-box {
      padding: 20px 0;

      .header__link {
        margin: 0;
        padding-right: 15px;
      }
    }

    &__content, &__navbar {
      width: 100%;
      overflow: unset;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0;
      margin-top: -10px;
    }

    &__col {
      margin-top: 10px;
      width: calc(100% / 2 - 5px);
      padding: 5px;

      &:not(:last-child) {
        margin-right: 0;
      }

      border: 5px solid var(--color-sky-blue);
      background-color: var(--color-sky-blue);
    }

    &__navbar {
      .header__col {
        &:first-child {
          width: calc(100% / 2 - 5px);
        }
      }
    }

    &__title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 0;

      &::after {
        content: "";
        margin-top: 13px;
        width: 5px;
        height: 5px;
        border-bottom: 1px solid var(--color-black);
        border-right: 1px solid var(--color-black);
        transform: translate(-50%, -75%) rotate(45deg);
        transition: 0.3s ease;
      }

      &.active {
        margin-bottom: 10px;
        color: var(--color-blue);

        &::after {
          border-color: var(--color-blue);
          transform: translate(-50%, -50%) rotate(225deg);
        }
      }
    }

    &__link-box {
      padding-right: 5px;
      width: 100%;
      max-height: 0;
      transition: 0.3s ease;
      opacity: 0;
      visibility: hidden;

      &.active {
        max-height: 155px;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @mixin media 568 {
    &__col {
      width: 100%;
    }

    &__navbar {
      .header__col {
        &:first-child {
          width: 100%;
        }
      }
    }
  }

  @mixin media 374 {
    &__burger-menu, &__logo {
      margin-right: 5px;
    }
  }
}
