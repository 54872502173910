h1 {
  font-weight: 700;
  font-size: ac(70px, 33px);
  line-height: 105.5%;
}

h2 {
  font-size: ac(30px, 24px);
  line-height: ac(40px, 36px);
  font-weight: 600;
}

h3 {
  font-weight: 500;
  font-size: ac(26px, 22px);
  line-height: 131.5%;
}

h6 {
  font-weight: 700;
  font-size: 15px;
  line-height: 18.7px;
}

a {
  font-size: ac(16px, 14px);
  display: inline-block;
  transition: 0.3s ease;
  line-height: 1.5;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/gulpstart2/icomoon.eot?bfl55p');
  src:  url('../fonts/gulpstart2/icomoon.eot?bfl55p#iefix') format('embedded-opentype'),
  url('../fonts/gulpstart2/icomoon.woff2?bfl55p') format('woff2'),
  url('../fonts/gulpstart2/icomoon.ttf?bfl55p') format('truetype'),
  url('../fonts/gulpstart2/icomoon.woff?bfl55p') format('woff'),
  url('../fonts/gulpstart2/icomoon.svg?bfl55p#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pinterest:before {
  content: "\e908";
}
.icon-filters:before {
  content: "\e907";
}
.icon-mail:before {
  content: "\e903";
}
.icon-telegram:before {
  content: "\e904";
}
.icon-instagram:before {
  content: "\e905";
}
.icon-arrow-down:before {
  content: "\e999";
}
.icon-close-burger:before {
  content: "\e910";
}
.icon-arrow-up:before {
  content: "\e902";
}
.icon-copy-right:before {
  content: "\e90b";
}
.icon-facebook:before {
  content: "\e90c";
}
.icon-linkedin:before {
  content: "\e90e";
}
.icon-phone:before {
  content: "\e906";
}
.icon-twitter:before {
  content: "\e90f";
}
.icon-arrow-next:before {
  content: "\e900";
}
.icon-arrow-prev:before {
  content: "\e901";
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/dinitrol-direct/icomoon.eot?1hmsw2');
  src:  url('../fonts/dinitrol-direct/icomoon.eot?1hmsw2#iefix') format('embedded-opentype'),
  url('../fonts/dinitrol-direct/icomoon.woff2?1hmsw2') format('woff2'),
  url('../fonts/dinitrol-direct/icomoon.ttf?1hmsw2') format('truetype'),
  url('../fonts/dinitrol-direct/icomoon.woff?1hmsw2') format('woff'),
  url('../fonts/dinitrol-direct/icomoon.svg?1hmsw2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-twitter:before {
  content: "\e923";
}
.icon-youtube:before {
  content: "\e922";
}
.icon-facebook:before {
  content: "\e924";
}
.icon-instagram:before {
  content: "\e921";
}
.icon-linkedin:before {
  content: "\e925";
}
.icon-filter:before {
  content: "\e908";
}

@font-face {
  font-family: "icomoon";
  src: url("../fonts/icomoon.eot?anxdwx");
  src: url("../fonts/icomoon.eot?anxdwx#iefix") format("embedded-opentype"),
  url("../fonts/icomoon.woff2?anxdwx") format("woff2"),
  url("../fonts/icomoon.ttf?anxdwx") format("truetype"),
  url("../fonts/icomoon.woff?anxdwx") format("woff"),
  url("../fonts/icomoon.svg?anxdwx#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\e900";
}
.icon-cart:before {
  content: "\e901";
}
.icon-check:before {
  content: "\e902";
}
.icon-close:before {
  content: "\e903";
}
.icon-delete:before {
  content: "\e904";
}
.icon-download:before {
  content: "\e905";
}
.icon-minus:before {
  content: "\e906";
}
.icon-plus:before {
  content: "\e907";
}
.icon-filter:before {
  content: "\e908";
}
