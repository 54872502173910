.cart {
	margin-top: ac(68px, 50px);

	.container {
		max-width: 1400px;
	}

	&__box {
		border: 1px solid var(--color-grey);
	}

	&__title {
		margin: 12px ac(34px, 15px) 65px;
	}

	&__link {
		display: flex;
		align-items: center;

		&:hover {
			color: var(--color-blue);
	  	}
	}

	&__descr {
		font-weight: 500;
		color: var(--color-liver);
		&:first-child {
			margin-left: auto;
		}
		&:not(:last-child) {
			margin-right: 118px;
		}
	}

	&__product {
		display: flex;
		align-items: center;
		padding: 20px 32px 30px 28px;

		&:first-child {
			.cart__price, .cart__counter-box, .cart__total {
				&::before {
					display: block;
				}
			}
		}

		&:not(:first-child) {
			padding-top: 30px;
			border-top: 1px solid var(--color-border);
		}
	}

	&__img {
		flex: 0 0 auto;
		width: 62px;
		height: 77px;
	}

	&__name, &__price, &__total {
		font-size: 20px;
		font-weight: 500;
	}
	&__price , &__total, &__input {
		text-align: center;
	}

	&__price , &__total {
		min-width: 150px;
	} 

	&__btn, &__input {
		border: 1px solid var(--color-border);
		font-size: 15px;
	}

	&__btn {
		display: flex;
		align-items: center;
		justify-content: center;

		cursor: pointer;
	}

	&__name {
		margin-right: 20px;
		margin-left: 31px;
	}

	&__price-box {
		align-self: flex-start;
		display: flex;
		align-items: center;
		margin-left: auto;
		margin-right: ac(83px, 10px);

		flex-shrink: 0;
	}
	
	&__price, &__counter-box, &__total {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 40px;

		&::before {
			content: 'Price';
			display: none;
			position: absolute;
			top: -68px;
			font-size: 18px;
			font-weight: 500;
			color: var(--color-liver);
		}
	}

	&__price {
		margin-right: ac(47px, 10px);
	}

	&__total {
		&::before {
			content: 'Total';
		}
	}

	&__counter-box {
		align-items: center;
		margin-right: ac(34px, 10px);

		&::before {
			content: 'Quantity';
		}

		&>* {
			width: 40px;
			height: 40px;
		}
	}

	&__input {
		border-top: 1px solid var(--color-border);
		border-bottom: 1px solid var(--color-border);
	}

	&__delete {
		transform: rotate(45deg);
    	font-size: 16px;
		cursor: pointer;

		flex-shrink: 0;

		&:before {
			transition: color .3s ease;
		}

		&:hover {
			&:before {
				color: var(--color-red);
			}
		}
	}

	@mixin media 1024 {
		margin-top: 35px;

		&__title {
			margin-bottom: ac(25px, 15px);
			text-align: center;
		}

		&__product {
			flex-wrap: wrap;
			justify-content: space-between;
			padding: ac(25px, 15px);

			&:not(:first-child) {
				padding-top: ac(25px, 15px);
			}
		}

		&__price-box {
			width: 100%;
			flex-wrap: wrap;
			justify-content: center;
			margin-top: 60px;
			margin-right: 0;
			margin-left: 0;
			order: 3;
		}

		&__descr {
			width: 150px;
			text-align: center;
		}

		&__link {
			width: 90%;
		}

		&__name {
			text-align: center;
		}

		&__delete {
			order: 1;
		}

		&__price, &__counter-box, &__total {
			&::before {
				display: block;
				top: -40px;
			}
		}

	}

	@mixin media 568 {
		&__link {
			width: 100%;
			flex-direction: column;
		}

		&__name {
			margin: 0;
			order: 3;
		}

		&__img {
			width: 100%;
			margin-bottom: 20px;
			order: 2;

			img {
				object-fit: contain;
			}
		}

		&__delete {
			padding: 15px;
			margin-left: auto;
			order: -1;
		}

		&__price, &__total, &__counter-box {
			width: 100%;
			justify-content: flex-end;
			margin-right: 0;

			&::before {
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		&__price-box {
			position: relative;
			margin-top: 20px;
		}

		&__price, &__counter-box {
			margin-bottom: 15px;
		}
	}

	@mixin media 374 {
		margin-top: 35px;
	}
}