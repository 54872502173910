.login-form {
	border:  1px solid var(--color-grey);

	&__head {
		padding: ac(30px, 15px);
		border-bottom: 1px solid var(--color-grey);
		font-size: 15px;

		.login-form__link {
			margin-left: ac(6px, 0px);
		}
	}

	&__head, &__descr, &__link {
		font-size: 15px;
		line-height: 151.5%;
	}

	&__body {
		padding: ac(30px, 15px);
	}

	&__form {
		margin-top: ac(38px, 20px);
	}

	&__item {
		width: calc(100% - 20px) !important;
	}

	&__link {
		flex: 0 0 auto;
		color: var(--color-red);
		transition: color .3s ease;

		&:hover {
			color: var(--color-blue);
		}
	}

	&__row {
		display: flex;
		align-items: center;
		margin-top: 21px;
	}

	&__checkbox {
		position: relative;
  		display: flex;
  		flex-direction: row !important;
		align-items: center;
		margin-left: 23px;
		margin-top: 0 !important;
		cursor: pointer;

		input {
			width: 0;
			height: 0;
			visibility: hidden;
			padding: 0 !important;

			&:checked ~ span {
				border-color: var(--color-blue);
				&::before {
					opacity: 1;
				}
			}
		}

		span {
			display: flex;
			align-items: center;
			justify-content: center;
			flex: 0 0 auto;
			padding: 3px;
			width: 15px;
			height: 15px;
			border: 2px solid var(--color-black);
			background-color: var(--color-white);
			cursor: pointer;
			transition: 0.3s ease;

			&::before {
				content: "";
				width: 100%;
				height: 80%;
				border-bottom: 2px solid var(--color-blue);
				border-left: 2px solid var(--color-blue);
				transform: rotate(-45deg);
				opacity: 0;
				transition: .3s ease;
			}

			&:hover {
				border-color: var(--color-blue);
			}
		}

		p {
			margin-bottom: 0 !important;
			margin-left: 12px;
			font-size: 14px;
			line-height: 30px;
		}
	}

	&__btn-box {
		margin-top: ac(32px, 20px);
	}

	@mixin media 568 {
		&__row {
			flex-direction: column;
		}

		&__checkbox {
			justify-content: center;
			margin-top: 15px !important;
			margin-left: 0;

			p {
				flex: 0 0 auto;
			}
		}

		&__btn-box {
			.btn {
				width: calc(100% - 20px);
			}
		}
	}
}