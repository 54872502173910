.first {
  margin-top: 21px;

  &__grid {
    display: grid;
    grid-template-columns: ac(880px, 300px) ac(580px, 200px) 1fr;
    grid-template-rows: repeat(2, minmax(ac(345px, 200px), auto));
    grid-template-areas: "item-1 item-2 item-3" "item-1 item-4 item-4";
    grid-column-gap: ac(24px, 15px);
    grid-row-gap: ac(20px, 10px);
  }

  &__item {
    position: relative;
    display: flex;
    padding: ac(76px, 20px) ac(85px, 20px) ac(80px, 60px) ac(75px, 20px);

    &:nth-child(1) {
      grid-area: item-1;

      .first {
        &__row {
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
          flex-wrap: wrap;
          width: 100%;
        }

        &__title {
          max-width: 520px;
          margin-right: ac(20px, 0px);
        }

        &__btn {
          margin-top: ac(22px, 15px);
          margin-bottom: ac(11px, 0px);
        }
      }
    }

    &:nth-child(2) {
      grid-area: item-2;
      padding: ac(44px, 20px) ac(45px, 20px) ac(49px, 20px) ac(80px, 20px);

      .first {
        &__title {
          margin-bottom: ac(22px, 15px);
        }
      }
    }

    &:nth-child(3) {
      grid-area: item-3;
      margin-left: ac(-5px, 0px);
      padding: ac(73px, 20px) ac(49px, 20px) ac(50px, 20px) ac(60px, 20px);

      .first {
        &__title {
          margin-bottom: ac(25px, 15px);
        }
      }
    }

    &:nth-child(4) {
      grid-area: item-4;
      padding: ac(80px, 20px) ac(49px, 20px) ac(91px, 20px) ac(78px, 20px);

      .first {
        &__title {
          margin-bottom: ac(25px, 15px);
        }
      }
    }
  }

  &__slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &__slide {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--gradient-black);
    }
  }

  &__pagination {
    bottom: ac(37px, 10px) !important;

    .swiper-pagination-bullet {
      margin: 0 5px !important;
      width: 40px;
      height: 6px;
      background-color: var(--color-white);
      opacity: 0.35;
      border-radius: 0;

      &-active {
        opacity: 1;
      }
    }
  }

  &__row {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__title {
    font-size: ac(50px, 28px);
    line-height: ac(48px, 28px);
    font-weight: 800;
    color: var(--color-white);

    &--lg {
      font-size: ac(70px, 30px);
      line-height: ac(65px, 30px);
    }

    &--sm {
      font-size: ac(30px, 22px);
      line-height: ac(30px, 22px);
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--gradient-black);
    }
  }

  @mixin media 768 {
    &__grid {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: repeat(3, minmax(200px, auto));
      grid-template-areas:
        "item-1 item-2"
        "item-1 item-3"
        "item-4 item-4";
    }

    &__item {
      &:first-child {
        padding-bottom: 40px;
      }
    }

    &__title {
      font-size: 26px;
      line-height: 30px;

      &--lg {
        font-size: 28px;
        line-height: 32px;
      }
    }
  }

  @mixin media 568 {
    &__grid {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, minmax(150px, auto));
      grid-template-areas:
        "item-1"
        "item-2"
        "item-3"
        "item-4";
    }

    &__bg {
      img {
        object-position: top;
      }
    }
    &__slide,
    &__bg {
      &::before {
        background: var(--color-black);
        opacity: 0.3;
      }
    }
  }

  @mixin media 374 {
    &__title {
      font-size: 22px;
      line-height: 26px;
    }
  }
}
