.vehicle {
    margin-top: ac(31px, 25px);

    .container {
        max-width: 1238px;
    }

    &__title {
        font-weight: 500;
        margin-bottom: ac(32px, 20px);
    }

    &__title, &__subtitle {
        text-align: center;
    }

    &__subtitle {
        max-width: 90.5ch;
        margin: 0 auto;
        line-height: ac(24px, 23px);

        p:not(:first-child) {
            margin-top: ac(20px, 10px);
        }
    }

    &__wrap {
        display: flex;
        justify-content: space-between;
        margin-top: ac(60px, 30px);
    }

    &__card {
        position: relative;
        width: calc(100% / 2 - 10px);
        overflow: hidden;

        &:nth-child(2) {
            .vehicle__col {
                background-color: #F4F5F9;
            }
        }
    }

    &__bg {
        position: absolute;
        top: 1px;
        right: 0;
        width: 100%;
        max-width: 302px;
        height: 101%;
        z-index: -1;
        overflow: hidden;
    }

    &__col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
        padding: ac(30px, 15px);
        background-color: var(--color-sky-blue);
        clip-path: polygon(0 0,71% 0,48% 100%,0 100%);
    }

    &__name {
        max-width: 63%;
        margin-bottom: 12px;
        font-weight: 700;
        letter-spacing: 0.03em;
        line-height: ac(30px, 25px);
    }

    &__descr {
        display: flex;
        align-items: center;
        max-width: 60%;
        font-size: 14px;
        line-height: 155%;
        color: var(--color-liver);

        &:not(:first-of-type) {
            margin-top: 4px;
        }

        &:last-of-type {
            margin-bottom: ac(49px, 30px);
        }

        span {
            width: 28px;
            height: 33px;
            margin-right: 13px;
            flex: 0 0 auto;

            img {
                object-fit: contain;
                object-position: center;
            }
        }
    }

    &__btn {
        min-width: unset;
        padding-left: 45px;
        padding-right: 45px;
        height: ac(47px, 40px);
        border-radius: 0;
        margin-top: auto;
    }

    @mixin tab-md {
        &__wrap {
            flex-direction: column;
        }

        &__card {
            width: 100%;
            &:not(:first-child) {
                margin-top: ac(20px, 15px, 375, 900);
            }
        }

        &__descr {
            &:last-of-type {
                margin-bottom: 12px;
            }
        }
    }

    @mixin mob-lg {
        &__bg {
            position: relative;
            max-width: 100%;
            height: 150px;
        }

        &__col {
            clip-path: unset;
        }

        &__name, &__descr {
            max-width: 100%;
        }
    }

    @mixin media 431 {

        &__btn {
            width: 100%;
        }
    }
}