.steps {
    margin-top: ac(148px, 65px);

    .container {
        max-width: 1540px;
    }

    &__row {
        display: flex;
        padding-right: ac(151px, 0px, 1440, 1920);

        &:not(:last-child) {
            margin-bottom: ac(60px, 25px);
        }
    }

    &__num-box {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        margin-right: ac(63px, 15px);

        p {
            margin-top: ac(26px, 10px);
            font-weight: 500;
            font-size: ac(26px, 20px);
            line-height: 151.5%;
            text-transform: uppercase;
            color: var(--color-blue);
        }
    }

    &__num {
        display: flex;
        align-items: center;
        justify-content: center;

        width: ac(88px, 44px);
        height: ac(88px, 44px);
        border-radius: 100%;
        font-weight: 500;
        font-size: ac(40px, 24px);
        line-height: 150.5%;
        color: var(--color-white);
        background-color: #1C60AB;
    }

    &__content {
        width: 100%;
    }

    &__title {
        line-height: 151.5%;
    }

    &__subtitle {
        margin-top: ac(29px, 15px);
        line-height: 151.5%;
        color: var(--color-blue);
    }

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: ac(-20px, -15px);
        margin-top: ac(30px, 20px);
    }

    &__card {
        display: flex;
        justify-content: space-between;
        width: calc(100% / 2 - ac(10px, 5px));
        padding: ac(29px, 15px) ac(29px, 15px) ac(28px, 15px);
        margin-bottom: ac(20px, 15px);
        background-color: #F4F5F9;
        border: 1px solid #F4F5F9;
        transition: .3s ease;

        &:not(.steps__card--full) {
            .steps__img {
                margin-top: ac(6px, 0px);
            }
            &:hover  {
                background-color: var(--color-white);
                border-color: var(--color-grey);
            }
         }

        &--full {
            width: 100%;
            padding: ac(29px, 15px);
            align-items: center;

            .steps__text {
                margin-right: ac(30px, 10px);
            }

            .steps__img {
                width: ac(414px, 150px);
                height: ac(184px, 100px);
            }
        }

        &--alt {
            .steps__text {
                margin-right: ac(19px, 10px);
            }

            .steps__img {
                width: ac(190px, 150px);
                height: ac(146px, 110px);
            }
        }
    }

    &__text {
        margin-right: ac(30px, 10px);
    }

    &__name {
        margin-bottom: 10px;
        line-height: 151.5%;
        color: var(--color-blue);
    }

    &__descr {
        font-size: ac(18px, 16px);
        line-height: 151.5%;
        color: var(--color-liver);
    }

    &__img {
        flex: 0 0 auto;
        width: ac(150px, 100px);
        height: ac(173px, 115px);

        img {
            object-fit: contain;
        }
    }

    @mixin tab-md {
        &__card {
            width: 100%;
        }
    }

    @mixin mob-lg {
        &__row {
            flex-direction: column;
        }

        &__num-box {
            flex-direction: row;
            margin-bottom: ac(35px, 15px);

            p {
                margin-top: 0;
            }
        }

        &__num {
            margin-right: 15px;
        }
    }

    @mixin media 431 {
        &__card {
            flex-direction: column-reverse;
        }

        &__img {
            margin: 0 auto 15px;
            width: 100%;
        }
    }
}