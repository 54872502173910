/* Diy kits pages */
.guide-list, .steps-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-gap: 20px;

    @mixin media 1150 {
        grid-template-columns: repeat(2, 1fr);

        .steps-list__item:last-child {
            grid-column: -1 / 1;

            img {
                object-position: top;
            }
        }
    }

    @mixin tab-sm {
        grid-template-columns: 1fr;
    }

    &__item {
        max-width: 484px;
        min-height: 320px;
        padding: 27px 30px 30px;
        position: relative;

        display: flex;
        flex-direction: column;

        &-icon {
            size: ac(100px, 75px);

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &-title {
            margin-bottom: 28px;
            color: var(--color-white);
            line-height: 147%;
        }

        &-bg {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: -1;

            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, #0A1621 15.27%, rgba(11, 25, 38, 0) 100%);
                left: 0;
                top: 0;
                z-index: 1;
                pointer-events: none;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .btn {
            margin-top: auto;
            padding: 12px 46px;
            width: fit-content;
            border-radius: 0;
        }

        @mixin media 1150 {
            max-width: 100%;
        }

        @mixin tab-sm {
            min-height: ac(320px, 300px, 375, 768);
        }

        @mixin mob {
            .btn {
                width: 100%;
                padding: 12px 25px;
            }
        }
    }
}

.diy-kits-text, .diy-kits-img {
    flex: 1;
}

.diy-kits-offer {
    &__title {
        font-weight: 500;
    }

    &__text {
        line-height: 151.5%;
        color: var(--color-liver);
    }
}

.diy-kits-text__inner {
    p {
        line-height: 151.5%;
        color: var(--color-liver);

        a {
            font-size: inherit;
            text-decoration: underline;
        }
    }

    ol,
    ul {
        li {
            line-height: 151.5%;
            position: relative;
            padding-left: ac(26px, 20px);

            &:before {
                position: absolute;
                left: 0;
                margin-top: -1px;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    ol {
        li {
            counter-increment: num;

            &::before {
                content: counter(num);
            }
        }
    }

    ul {
        list-style: none;
        li {
            transition: all 0.3s ease;

            &:before {
                content: '•';
                transition: all 0.3s ease;
            }
        }
    }

}

.rustproofing-info {
    &__icon {
        size: ac(100px, 75px);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .services-list {
        display: flex;

        &__item {
            display: flex;
            align-items: center;
            min-width: ac(329px, 265px, 1440, 1920);

            padding: 20px;
            background: #F4F5F9;
            transition: 0.3s ease;

            &-icon {
                size: ac(54px, 40px);
                margin-right: 16px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            &-title {
                color: var(--color-blue);
            }

            &:hover {
                background: var(--color-sky-blue);
            }

            @mixin tab-md {
                width: 100%;
            }
        }
    }

    p {
        line-height: 151.5%;
        color: var(--color-liver);
    }
}

.step-guide-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: ac(60px, 40px, 375, 1920);
    overflow: hidden;

    &__item {

        &-line {
            height: 4px;
            background: var(--color-blue);
            position: relative;
            width: 100%;
            margin: 45px 0;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                size: 34px;
                border-radius: 50%;
                background: var(--color-blue);
            }
        }

        &-text {
            margin-top: 10px;
        }
    }

    .steps-guide-list__item-title,
    .steps-guide-list__item-text {
        color: var(--color-liver);
    }

    .steps-guide-list__item-text,
    .steps-guide-list__item-subtitle {
        line-height: 151.5%;

        padding-right: 30px;

        @mixin tab-sm {
            padding-right: 0;
        }
    }

    @mixin media 1151 {
        overflow: visible;
        grid-template-columns: repeat(2, 1fr);
    }

    @mixin tab-sm {
        grid-template-columns: 1fr;
    }
}