.search {
  padding: ac(25px, 20px) ac(28px, 20px) ac(30px, 20px);
  margin-top: ac(21px, 15px);
  background-color: var(--color-sky-blue);

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__text {
    margin-bottom: 5px;
    font-size: 16px;

    &--sm {
      font-size: 15px;
    }
  }

  &__line {
    width: 100%;
    margin: ac(20px, 10px) 0 ac(25px, 15px);
    height: 1px;
    background-color: var(--color-tropical);
  }

  &__select {
    width: 100%;
    max-width: 464px;
    .list {
      li {
        &:first-child {
          display: block;
        }
      }
    }
  }

  &__input {
    width: 100%;
    margin-bottom: 8px;
    padding: 6px 11px;
    border: 1px solid var(--color-grey);
    background-color: var(--color-white);
    @mixin transparent-bg-input var(--color-black);
  }

  &__btn {
    margin-top: ac(30px, 20px);
  }

  @mixin media 374 {
    &__btn {
      width: 100%;
    }
  }
}
