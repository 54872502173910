.about-us {
  margin-top: ac(95px, 50px);

  &__row {
    margin: 0 ac(301px, 0px);
  }

  &__col {
    margin-right: ac(153px, 40px);
  }

  &__title {
    font-weight: 500;
    line-height: 20px;
  }

  &__descr {
    margin-top: ac(33px, 0px, 768, 1440);
    font-size: ac(30px, 24px);
    line-height: ac(30px, 24px);
    font-weight: 600;
  }

  &__text {
    margin-top: ac(30px, 20px);
  }

  &__img {
    position: relative;
    flex: 0 0 auto;
    width: ac(586px, 300px);
  }

  &__decor {
    position: absolute;
    top: calc(50% + 36px);
    left: 0;
    width: ac(144px, 90px);
    height: ac(134px, 90px);
    transform: translateX(-50%);

    img {
      object-fit: contain;
    }
  }

  @mixin media 768 {
    &__row {
      flex-direction: column;
    }

    &__col {
      margin-bottom: 20px;
      margin-right: 0;
    }

    &__descr,
    &__text {
      margin-top: 15px;
    }

    &__descr {
      font-size: 18px;
    }

    &__img {
      width: 100%;
      max-width: 450px;
      height: 200px;
      align-self: center;
    }

    &__decor {
      top: 50%;
    }
  }

  @mixin media 568 {
    &__img {
      width: 100%;
      height: 150px;
    }

    &__decor {
      top: 0;
      left: 0;
      transform: unset;
    }
  }

  @mixin media 374 {
    margin-top: 35px;
  }
}
