.products-view {
  padding-top: ac(90px, 50px);
  padding-bottom: 0;

  &__container {
    max-width: 1350px;
    margin: 0 auto;
  }

  .back-btn {
    display: flex;
    align-items: center;

    .arrow {
      width: 40px;
      height: 40px;
      padding: 5px;
      border: 1px solid var(--color-grey);
      cursor: pointer;
      margin-right: 10px;

      &:before {
        width: 12px;
        height: 12px;
        border-bottom: 2px solid var(--color-black);
        border-right: 2px solid var(--color-black);
      }

      &:hover {
        border: 1px solid var(--color-blue);
        background: var(--color-blue);
        color: var(--color-white);

        &:before {
          border-bottom: 2px solid var(--color-white);
          border-right: 2px solid var(--color-white);
        }
      }
    }

    p {
      cursor: pointer;
      transition: color 0.3s ease;

      &:hover {
        color: var(--color-blue);
        transition: color 0.3s ease;
        text-decoration: underline;
      }
    }
  }

  .products-thumbs-slider {
    .thumbs-slider-top {
      width: 100%;
      height: ac(453px, 240px);
      padding-bottom: 20px;

      .swiper-slide {
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background: var(--color-sky-blue);
          background-blend-mode: multiply;
          mix-blend-mode: multiply;
          z-index: 10;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        iframe {
          width: 100% !important;
          height: 100% !important;
        }
      }
    }

    .thumbs-slider-bottom {
      padding: 1px 1px 20px;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
      }

      &__photo,
      &__video {
        position: relative;
        max-width: 132.5px;
        max-height: 132.5px;

        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background: var(--color-sky-blue);
          background-blend-mode: multiply;
          mix-blend-mode: multiply;
          z-index: 10;
        }

      }

      &__photo {
        width: 100%;
        @mixin aspect-ratio 1, 1;
      }

      &__video {
        width: 100%;
        @mixin aspect-ratio 1, 1;
        position: relative;

        &:before,
        &:after {
          position: absolute;
          content: '';
        }

        &:before {
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: var(--color-black);
          opacity: 0.05;
          z-index: 1;
        }

        &:after {
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          background-image: url('../images/icons/play-icon.svg');
          background-size: 100% 100%;
          background-position: center;
          width: 50%;
          height: 50%;
          z-index: 2;
          cursor: pointer;
          transition: all 0.3s ease;

          &:hover {
            transition: all 0.3s ease;
            opacity: 0.8;
          }
        }

        iframe {
          width: 100%;
          height: auto;
        }
      }

      .swiper-slide {
        max-width: 136.25px;
        transition: all 0.3s ease;
        border: 2px solid transparent;
        border-radius: 2px;
        overflow: hidden;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &:hover {
          border: 2px solid var(--color-grey);
        }

        &.swiper-slide-thumb-active {
          border: 2px solid var(--color-grey);

          &:hover {
            border: 2px solid var(--color-blue);
          }
        }
      }
    }
  }

  .product-view {
    padding-top: ac(25px, 20px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding-bottom: ac(48px, 20px);

    .products-thumbs-slider {
      width: 44.74%;
      position: sticky;
      bottom: 0;
      height: 100%;
      left: 0;
      top: 64px;
    }

    @mixin media 769 {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      .products-thumbs-slider {
        width: 100%;
        padding-bottom: 30px;
        position: static;
        height: auto;
      }
    }

    &__info {
      width: 50.07%;
      border: none;
      box-shadow: none;
      padding-top: 5px;

      @mixin media 769 {
        width: 100%;
      }

      &:hover {
        box-shadow: none;
      }

      h2 {
        font-size: ac(32px, 24px);
        line-height: ac(38px, 36px);
        font-weight: 500;
        max-width: ac(460px, 360px);
        margin-bottom: 20px;
      }

      h3 {
        font-weight: 500;
        font-size: ac(24px, 20px);
        line-height: 120.83%;
        margin-bottom: 10px;
        &:not(:first-child) {
          margin-top: 20px;
        }
      }

      p,
      li,
      input {
        font-weight: 400;
        font-size: 15px;
        line-height: 131.02%;
      }

      a {
        font-size: inherit;
        text-decoration: underline;
      }

      li {
        line-height: 24.5px;
      }

      .info-block {
        margin-bottom: 20px;

        &--bottom {
          padding-top: ac(25px, 15px);

          table {
            td {
              border: 1px solid var(--color-grey);
            }
            
            tr {
              td {
                padding: ac(15px, 10px) ac(25px, 15px);
                font-size: 15px;
                line-height: 131.02%;

                &:first-child {
                  font-size: ac(18px, 16px);
                  background-color: var(--color-sky-blue);

                  &, &>* {
                    font-weight: 500 !important;
                  }
                }
              }
            }
          }

          p {
            img {
              display: inline-block;
            }
          }

          p,
          li {
            color: var(--color-liver);
          }

          p,ul,ol {
            &:not(:last-child) {
              margin-bottom: ac(10px, 5px);
            }
          }
        }

        ol,
        ul {
          li {
            position: relative;
            padding-left: 26px;

            &:before {
              position: absolute;
              top: 0;
              left: 0;
              width: 26px;
              height: 24px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        ol {
          li {
            counter-increment: num;

            &::before {
              content: counter(num);
            }
          }
        }

        ul {
          list-style: none;
          li {
            transition: all 0.3s ease;

            &:before {
              content: '•';
              transition: all 0.3s ease;
            }
          }
        }
      }

      .options-product {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;

        &__selected {
          font-weight: 400;
          font-size: 15px;
          line-height: 131.02%;
        }

        &__btn {
          margin-top: 10px;
          padding: 4px 4px 4px 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 400;
          font-size: 15px;
          width: 68.64%;
          border: 1px solid var(--color-grey);
          cursor: pointer;
          min-width: 360px;

          @mixin media 450 {
            min-width: 100%;
            width: 100%;
          }

          .btn {
            &--options {
              width: 36px;
              height: 36px;
              background: var(--color-porcelain);
              min-width: 36px;
              margin-left: 10px;

              &:before {
                transform: scale(-1, -1);
                transition: transform 0.3s ease;
              }

              &:hover {
                background: var(--color-blue);
              }
            }
          }

          &.active {
            .btn {
              &--options {
                &:before {
                  transform: scale(1, 1);
                }
              }
            }
          }
        }

        &__list {
          position: absolute;
          bottom: 0;
          left: 0;
          border: 1px solid var(--color-grey);
          border-radius: 0;
          width: 68.64%;
          transform: translateY(50%) scale(0);
          opacity: 1;
          margin-top: 4px;
          box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
          background: var(--color-white);
          z-index: 10;
          transition: all 0.3s ease;
          min-width: 360px;

          @mixin media 450 {
            min-width: 100%;
            width: 100%;
          }

          li {
            padding: 0 12px;
            height: 43px;
            display: flex;
            align-items: center;
            transition: background-color 0.3s ease;
            cursor: pointer;

            &:hover {
              transition: background-color 0.3s ease;
              background-color: var(--color-sky-blue);
            }
          }

          &.active {
            transform: translateY(calc(100% + 4px)) scale(1);
            opacity: 1;
          }
        }
      }

      .nice-select {
        margin-top: 10px;
        .list {
          .option {
            display: flex;
            align-items: center;
          }
        }
      }

      .price-buy-block {
        padding-top: 20px;
        padding-bottom: ac(25px, 15px);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        &:not(:has(.availability)) {
          .cart__total {
            padding: ac(10px, 5px) ac(25px, 10px);
            max-width: 100%;
            height: auto;
            font-size: ac(30px, 20px);
            line-height: ac(38px, 30px);
            border: 1px solid var(--color-red);
            background-color: var(--color-pink);
            color: var(--color-red);
            opacity: .8;
            justify-content: center;
          }
        }

        .price {
          font-size: ac(34px, 24px);
          line-height: ac(40px, 36px);
          font-weight: 700;
          margin-bottom: 5px;
        }

        .availability,
        .vendor-code {
          color: var(--color-liver);
          font-weight: 400;
          font-size: 15px;
          line-height: 131.02%;
        }

        .cart__price {
          &::before {
            display: none;
          }
        }

        .counter-block {
          display: flex;
          align-items: center;
          width: max-content;
          padding-top: ac(20px, 15px);
          padding-bottom: ac(30px, 20px);

          .count-input {
            height: ac(40px, 36px);
            padding: 5px 8px;
            border: 1px solid var(--color-grey);
            border-left: 0;
            border-right: 0;
            width: ac(40px, 36px);
            text-align: center;
            color: var(--color-black);
            transition: all 0.3s ease;

            &:focus {
              border: 1px solid var(--color-grey);
              border-left: 0;
              border-right: 0;
            }
          }
        }

        .btn {
          min-width: ac(200px, 160px);
          margin-bottom: ac(20px, 15px);
          height: ac(50px, 44px);
          font-size: 16px;
        }
      }
    }

    &__download {
      padding: 6px 20px;
      height: ac(50px, 44px);

      min-width: ac(233px, 140px);
      margin-top: 10px;
      font-size: 16px;

      .icon-download {
        margin-left: 10px;
      }
    }
  }

  &__slider {
    max-width: 1350px;
    margin: 0 auto;

    &:before {
      content: none;
    }

    .products__img {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: var(--color-sky-blue);
        background-blend-mode: multiply;
        mix-blend-mode: multiply;
        z-index: 10;
      }
    }

    @mixin media 450 {
      width: calc(100% + 20px);
    }

    .products__slide {
      width: 100%;
      max-width: calc(25% - ac(50px, 20px));
      margin-right: ac(50px, 20px);

      @mixin media 769 {
        max-width: calc(33% - ac(50px, 20px));
      }

      @mixin media 450 {
        max-width: calc(50% - ac(50px, 20px));
      }
    }

    .products__row {
      margin-bottom: ac(50px, 20px);
      margin-right: ac(50px, 20px);

      .arrow {
        border: 1px solid var(--color-grey);
        cursor: pointer;

        &:hover {
          border: 1px solid var(--color-blue);
        }
      }
    }
  }
}

.btn-counter {
  padding: 5px;
  border: 1px solid var(--color-grey);
  width: ac(40px, 36px);
  height: ac(40px, 36px);
  font-size: 14px;
  color: var(--color-black);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  transition: all 0.3s ease;

  &:not(.disabled):hover {
    transition: all 0.3s ease;
    background: var(--color-blue);
    color: var(--color-white);
    border: 1px solid var(--color-blue);
  }

  &.disabled {
    background: var(--color-border);
    cursor: default;
  }
}
