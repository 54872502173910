html {
  &::-webkit-scrollbar {
    width: 8px;
    background-color: var(--color-sky-blue);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-blue);
  }

  scrollbar-width: thin;
  scrollbar-color: var(--color-blue) var(--color-sky-blue);
}

body {
  position: relative;
  min-width: 320px;
  margin: auto;
  font-size: ac(18px, 16px);
  line-height: ac(30px, 26px);
  font-weight: 400;
  background: var(--color-white);
  color: var(--color-black);
  font-family: var(--font-main);
  overflow-x: hidden;
}

#site {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex-grow: 1;
}

.container {
  width: 100%;
  max-width: 1840px;
  margin: 0 auto;
  padding: 0 25px;

  &.sm {
    max-width: 1535px;
  }
}

section {
  position: relative;
}

input {
  border-radius: 0;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fp-watermark {
  display: none;
}
.swiper-wrapper {
  left: 0 !important;
}

.arrows {
  display: flex;

  &--sec {
    .arrow {
      background-color: var(--color-sky-blue);

      &.swiper-button-disabled {
        opacity: .5;
        cursor: not-allowed;

        &:hover {
          background-color: var(--color-sky-blue);

          &::before {
            border-color: var(--color-black);
          }
        }
      }
    }
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px 9.5px;
  min-width: 140px;
  height: ac(44px, 40px);
  font-size: 15px;
  font-weight: 500;
  border: 1px solid var(--color-white);
  background-color: var(--color-white);
  transition: 0.3s ease;
  color: var(--color-black);
  text-decoration: none !important;

  &:hover {
    background-color: var(--color-blue);
    color: var(--color-white);
  }

  &.disable {
    &:hover {
      background-color: var(--color-white);
      border: 1px solid var(--color-white);
      color: var(--color-black);
    }
  }

  &--alt {
    color: var(--color-white);
    border: 1px solid var(--color-white);
    background-color: var(--color-blue);

    &:hover {
      background-color: var(--color-light-blue);
    }

    &.disable {
      &:hover {
        color: var(--color-white);
        background-color: var(--color-blue);
        border: 1px solid var(--color-white);
      }
    }
  }

  &--form {
    color: var(--color-white);
    background-color: var(--color-red);
    border: 1px solid var(--color-red);
    padding: 6px 15px;

    &:hover {
      border: 1px solid var(--color-grey);
      background-color: var(--color-blue);
    }

    &.disable {
      &:hover {
        color: var(--color-white);
        background-color: var(--color-red);
        border: 1px solid var(--color-red);
      }
    }
  }
  
  &.disable {
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.hidden-title {
  z-index: -100;
  visibility: hidden;
  opacity: 0;
  position: absolute;
}

.arrow {
  position: relative;
  width: ac(54px, 40px);
  height: ac(54px, 40px);
  background-color: var(--color-white);
  transition: background-color 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: ac(16px, 12px);
    height: ac(16px, 12px);
    border-bottom: 1px solid var(--color-black);
    border-right: 1px solid var(--color-black);
    transform: translate(-75%, -50%) rotate(-45deg);
    transition: border-color 0.3s ease;
  }

  &:hover {
    background-color: var(--color-blue);

    &::before {
      border-color: var(--color-white);
    }
  }

  &--prev {
    transform: rotate(180deg);
    margin-right: 10px;
  }

}

.form {
  margin-top: -20px;
  margin-right: -20px;

  & > * {
    margin-right: 20px;
  }

  .title {
    margin-top: 20px;
  }

  textarea {
    padding: 6px 11px;
    resize: none;
    border: 1px solid var(--color-grey);
    height: ac(152px, 100px);
  }

  label {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: calc(50% - 20px);

    p {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 10px;
    }

    input {
      padding: 6px 11px;
      border: 1px solid var(--color-grey);
      background-color: var(--color-white);
      @mixin transparent-bg-input var(--color-black);

      &::placeholder {
        font-size: 15px;
      }

      &.invalid {
        border: 1px solid var(--color-red);
      }
    }

    input + input {
      margin-top: 22px;
    }
  }

  .upload-btn {
    cursor: pointer;

    input {
      width: 0;
      height: 0;
      visibility: hidden;
      opacity: 0;
      padding: 0;
    }

    span {
      display: flex;
      align-items: center;

      width: 100%;
      max-width: 180px;
      padding: 4px 9px;
      font-size: 15px;
      border: 1px solid var(--color-grey);
      background-color: var(--color-white);
      transition: 0.3s ease;

      &::before {
        content: '';
        margin-right: 13px;
        width: 16.5px;
        height: 19.5px;
        background: url("data:image/svg+xml,%3Csvg width='18' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.25.25a1.5 1.5 0 0 0-1.5 1.5v16.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-12a.748.748 0 0 0-.22-.53L11.78.47a.747.747 0 0 0-.53-.22h-9Zm8.25 1.5H2.25v16.5h13.5V7h-4.5a.75.75 0 0 1-.75-.75v-4.5Zm4.19 3.75L12 2.81V5.5h2.69Z' fill='%23000'/%3E%3C/svg%3E")
          center / contain no-repeat;
        transition: background 0.3s ease;
      }
    }

    &:hover {
      span {
        background-color: var(--color-blue);
        color: var(--color-white);

        &::before {
          background: url("data:image/svg+xml,%3Csvg width='18' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.25.25a1.5 1.5 0 0 0-1.5 1.5v16.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-12a.748.748 0 0 0-.22-.53L11.78.47a.747.747 0 0 0-.53-.22h-9Zm8.25 1.5H2.25v16.5h13.5V7h-4.5a.75.75 0 0 1-.75-.75v-4.5Zm4.19 3.75L12 2.81V5.5h2.69Z' fill='%23fff'/%3E%3C/svg%3E")
            center / contain no-repeat;
        }
      }
    }
  }

  &--sec {
    margin-top: ac(30px, 25px);
    margin-right: 0;
    width: 100%;
    padding: ac(28px, 15px) ac(30px, 15px);
    border: 1px solid var(--color-grey);

    & > * {
      margin-right: 0px;
    }

    label {
      width: 100%;
    }

    h3 {
      margin-bottom: ac(40px, 20px);
    }
  }

  &--acc {
    max-width: 1290px;
    margin: ac(68px, 25px) auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    padding: ac(28px, 15px) ac(30px, 15px);
    border: 1px solid var(--color-grey);

    & > * {
      margin-right: 0px;
    }

    label {
      width: calc(50% - (15px, 7px));
    }

    h3 {
      margin-bottom: ac(40px, 20px);
    }

    .exit {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      height: 44px;
      background-color: var(--color-porcelain);
      cursor: pointer;
      transition: 0.3s ease;

      &::before {
        content: '';
        width: 24px;
        height: 24px;
        background: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 22a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v3h-2V4H6v16h12v-2h2v3a1 1 0 0 1-1 1H5Zm13-6v-3h-7v-2h7V8l5 4-5 4Z' fill='%23000'/%3E%3C/svg%3E")
          center/contain no-repeat;
      }

      &:hover {
        background-color: var(--color-blue);
      }
    }

    .avatar-box {
      position: relative;

      .avatar {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;
      }

      label {
        span {
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          overflow: hidden;
          width: 34px;
          height: 34px;
          background-color: var(--color-red);
          cursor: pointer;
          transition: 0.3s ease;

          &::before {
            content: '';
            width: 22px;
            height: 22px;
            background: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='m13.107 8.072-1.179-1.179-7.761 7.762v1.178h1.178l7.762-7.761Zm1.178-1.179 1.178-1.178-1.178-1.178-1.178 1.178 1.178 1.178ZM6.035 17.5H2.5v-3.536L13.696 2.768a.833.833 0 0 1 1.178 0l2.358 2.358a.833.833 0 0 1 0 1.178L6.036 17.5h-.001Z' fill='%23fff'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h20v20H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E")
              center/contain no-repeat;
          }

          &:hover {
            background-color: var(--color-blue);
          }
        }

        input {
          top: 0;
          right: 0;
          opacity: 0;
          padding: 0;
          position: absolute;
          visibility: hidden;
          z-index: -1;
          width: 0;
          margin: 0;
          height: 0;
        }
      }
    }

    .btn {
      margin-top: ac(30px, 25px);
    }
  }
}

.checkbox {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  input {
    width: 0;
    height: 0;
    visibility: hidden;

    &:checked ~ span {
      &::before {
        opacity: 1;
      }
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-white);
    background-color: var(--color-blue);
    cursor: pointer;
    transition: background-color 0.3s ease;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background-color: var(--color-white);
      opacity: 0;
    }

    &:hover {
      background-color: var(--color-light-blue);
    }
  }

  p {
    margin-left: 12px;
    font-size: 14px;
    line-height: 30px;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.info {
  width: 100%;
  padding: 15px 25px 3px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: var(--color-sky-blue);
  margin-right: ac(-59px, -20px);

  p {
    font-size: 14px;
    line-height: 1em;
    margin-right: ac(59px, 20px);
    margin-bottom: 10px;
  }

  span {
    display: inline-block;
    width: 14px;
    height: 10px;
    margin-right: 3px;

    img {
      object-fit: contain;
    }
  }
}

.nice-select {
  border-radius: 0;
  border: 1px solid var(--color-grey);
  height: 44px;
  padding-left: 11px;
  padding-right: 50px;
  min-width: 0;

  .current {
    max-height: 40px;
    font-size: 15px;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -moz-box;
    -moz-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    line-clamp: 1;
    box-orient: vertical;
    white-space: normal;
  }

  &::after {
    margin-top: -6px;
    right: 18px;
    width: 9px;
    height: 9px;
    border-color: var(--color-black);
  }

  &::before {
    content: '';
    position: absolute;
    right: 4px;
    top: 50%;
    width: 36px;
    height: 36px;
    transform: translate(0, -50%);
    background-color: var(--color-porcelain);
  }

  .list {
    width: 100%;
    border-radius: 0;
    border: 1px solid var(--color-grey);
    max-height: ac(500px, 200px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-black);
    }

    .option.selected {
      &.focus {
        background-color: var(--color-sky-blue);
      }
      font-weight: 400;
    }

    li {
      white-space: normal;
      padding: 0 12px;
      line-height: 43px;

      &:hover {
        background-color: var(--color-sky-blue);
      }

      &:first-child {
        display: none;
      }
    }
  }
}

.text-box {
  display: flex;
  width: 100%;
  max-width: 1400px;
  margin: ac(120px, 50px) auto 0;

  &__col {
    max-width: ac(886px, 450px);
    margin-right: ac(70px, 20px);

    & > h2 {
      margin-bottom: ac(29px, 15px);
      letter-spacing: ac(-0.3px, 0px);
    }

    & > h3 {
      margin-bottom: ac(24px, 15px);
    }

    & > p {
      line-height: 151.5%;
      &:first-of-type {
        color: var(--color-liver);
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      text-align: justify;
      margin-bottom: ac(29px, 15px);

      span {
        font-weight: 600;
      }
      & > a {
        color: var(--color-blue);
        text-decoration: underline;
        font-size: ac(18px, 16px);
        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__marked {
    padding: ac(19px, 15px);
    border: 1px solid var(--color-red);
    background-color: var(--color-pink);
    line-height: 157% !important;
  }

  &__map {
    flex-grow: 1;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.services {
  margin-top: ac(50px, 25px);

  &--blog {
    margin-top: ac(28px, 20px);
  }

  .container {
    max-width: 1400px;
  }

  &__wrapper {
    padding: 30px;
    background-color: var(--color-blue);
  }

  &__box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-bottom: -20px;
  }

  &__soc-box {
    display: flex;
    margin-left: auto;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    &:not(:first-child) {
      margin-left: 10px;
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transition: 0.3s ease;
    }

    &--fb {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 19h5V5H5v14h7v-5h-2v-2h2v-1.654c0-1.337.14-1.822.4-2.311.256-.484.652-.88 1.136-1.135.382-.205.857-.328 1.687-.381.329-.021.755.005 1.278.08v1.9H16c-.917 0-1.296.043-1.522.164a.727.727 0 0 0-.314.314c-.12.226-.164.45-.164 1.368V12h2.5l-.5 2h-2v5ZM4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Z' fill='%23FFFFFF'/%3E%3C/svg%3E");
      }

      &:hover {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 19h5V5H5v14h7v-5h-2v-2h2v-1.654c0-1.337.14-1.822.4-2.311.256-.484.652-.88 1.136-1.135.382-.205.857-.328 1.687-.381.329-.021.755.005 1.278.08v1.9H16c-.917 0-1.296.043-1.522.164a.727.727 0 0 0-.314.314c-.12.226-.164.45-.164 1.368V12h2.5l-.5 2h-2v5ZM4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Z' fill='%233380bd'/%3E%3C/svg%3E");
        }
      }
    }
    &--tw {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.3 5.55a2.9 2.9 0 0 0-2.9 2.847l-.029 1.575a.599.599 0 0 1-.68.583l-1.56-.212c-2.054-.28-4.022-1.226-5.91-2.799-.598 3.31.57 5.603 3.383 7.372l1.747 1.098a.6.6 0 0 1 .034.993L7.793 18.17c.947.059 1.846.017 2.591-.131 4.718-.942 7.855-4.492 7.855-10.348 0-.478-1.012-2.141-2.94-2.141Zm-4.9 2.81a4.9 4.9 0 0 1 8.385-3.355c.71-.005 1.316.175 2.668-.645-.334 1.64-.5 2.352-1.213 3.331 0 7.642-4.697 11.358-9.463 12.309-3.268.652-8.02-.419-9.382-1.841.694-.054 3.514-.357 5.144-1.55C5.159 15.7-.33 12.47 3.278 3.786c1.693 1.977 3.41 3.323 5.15 4.037 1.158.475 1.442.465 1.972.538V8.36Z' fill='%23FFFFFF'/%3E%3C/svg%3E");
      }

      &:hover {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.3 5.55a2.9 2.9 0 0 0-2.9 2.847l-.029 1.575a.599.599 0 0 1-.68.583l-1.56-.212c-2.054-.28-4.022-1.226-5.91-2.799-.598 3.31.57 5.603 3.383 7.372l1.747 1.098a.6.6 0 0 1 .034.993L7.793 18.17c.947.059 1.846.017 2.591-.131 4.718-.942 7.855-4.492 7.855-10.348 0-.478-1.012-2.141-2.94-2.141Zm-4.9 2.81a4.9 4.9 0 0 1 8.385-3.355c.71-.005 1.316.175 2.668-.645-.334 1.64-.5 2.352-1.213 3.331 0 7.642-4.697 11.358-9.463 12.309-3.268.652-8.02-.419-9.382-1.841.694-.054 3.514-.357 5.144-1.55C5.159 15.7-.33 12.47 3.278 3.786c1.693 1.977 3.41 3.323 5.15 4.037 1.158.475 1.442.465 1.972.538V8.36Z' fill='%233380bd'/%3E%3C/svg%3E");
        }
      }
    }
    &--in {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm1 2v14h14V5H5Zm2.5 4a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm-1 1h2v7.5h-2V10Zm5.5.43c.584-.565 1.266-.93 2-.93 2.071 0 3.5 1.679 3.5 3.75v4.25h-2v-4.25a1.75 1.75 0 0 0-3.5 0v4.25h-2V10h2v.43Z' fill='%23FFFFFF'/%3E%3C/svg%3E");
      }

      &:hover {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 3h16a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1Zm1 2v14h14V5H5Zm2.5 4a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm-1 1h2v7.5h-2V10Zm5.5.43c.584-.565 1.266-.93 2-.93 2.071 0 3.5 1.679 3.5 3.75v4.25h-2v-4.25a1.75 1.75 0 0 0-3.5 0v4.25h-2V10h2v.43Z' fill='%233380bd'/%3E%3C/svg%3E");
        }
      }
    }
    &--rd {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.102 7.815.75-3.536a2 2 0 0 1 2.374-1.54l3.196.68a2 2 0 1 1-.416 1.956l-3.196-.68-.666 3.135c1.784.137 3.557.73 5.163 1.7a3.192 3.192 0 0 1 4.74 2.673v.021a3.193 3.193 0 0 1-1.206 2.55 2.963 2.963 0 0 1-.008.123c0 3.998-4.45 7.03-9.8 7.03-5.331 0-9.707-3.024-9.704-6.953a5.337 5.337 0 0 1-.01-.181 3.192 3.192 0 0 1 3.454-5.35 11.446 11.446 0 0 1 5.329-1.628Zm9.286 5.526c.408-.203.664-.62.66-1.075a1.192 1.192 0 0 0-2.015-.806l-.585.56-.67-.455c-1.615-1.098-3.452-1.725-5.23-1.764h-1.006c-1.875.029-3.651.6-5.237 1.675l-.663.45-.584-.55a1.193 1.193 0 1 0-1.314 1.952l.633.29-.054.695c-.013.17-.013.339.003.584 0 2.71 3.356 5.03 7.708 5.03 4.37 0 7.799-2.336 7.802-5.106.013-.17.013-.339 0-.508l-.052-.672.604-.3ZM7 13.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm7 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm-1.984 5.103c-1.397 0-2.767-.37-3.882-1.21a.424.424 0 0 1 .597-.597c.945.693 2.123.99 3.269.99s2.33-.275 3.284-.959a.44.44 0 0 1 .732.206.47.47 0 0 1-.12.423c-.683.797-2.483 1.147-3.88 1.147Z' fill='%23FFFFFF'/%3E%3C/svg%3E");
      }

      &:hover {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m11.102 7.815.75-3.536a2 2 0 0 1 2.374-1.54l3.196.68a2 2 0 1 1-.416 1.956l-3.196-.68-.666 3.135c1.784.137 3.557.73 5.163 1.7a3.192 3.192 0 0 1 4.74 2.673v.021a3.193 3.193 0 0 1-1.206 2.55 2.963 2.963 0 0 1-.008.123c0 3.998-4.45 7.03-9.8 7.03-5.331 0-9.707-3.024-9.704-6.953a5.337 5.337 0 0 1-.01-.181 3.192 3.192 0 0 1 3.454-5.35 11.446 11.446 0 0 1 5.329-1.628Zm9.286 5.526c.408-.203.664-.62.66-1.075a1.192 1.192 0 0 0-2.015-.806l-.585.56-.67-.455c-1.615-1.098-3.452-1.725-5.23-1.764h-1.006c-1.875.029-3.651.6-5.237 1.675l-.663.45-.584-.55a1.193 1.193 0 1 0-1.314 1.952l.633.29-.054.695c-.013.17-.013.339.003.584 0 2.71 3.356 5.03 7.708 5.03 4.37 0 7.799-2.336 7.802-5.106.013-.17.013-.339 0-.508l-.052-.672.604-.3ZM7 13.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm7 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm-1.984 5.103c-1.397 0-2.767-.37-3.882-1.21a.424.424 0 0 1 .597-.597c.945.693 2.123.99 3.269.99s2.33-.275 3.284-.959a.44.44 0 0 1 .732.206.47.47 0 0 1-.12.423c-.683.797-2.483 1.147-3.88 1.147Z' fill='%233380bd'/%3E%3C/svg%3E");
        }
      }
    }
  }

  &__text {
    font-size: 20px;
    font-weight: 500;
    color: var(--color-white);
  }

  &__btn,
  &__text {
    margin-right: 20px;
    margin-bottom: 20px;
  }

  &__btn {
    width: 100%;
    max-width: 200px;
    padding: 12px 15px ac(11px, 10px);
    margin-left: auto;
    font-size: 16px;
    font-weight: 600;
  }
}

.checkout {
  margin-top: ac(100px, 50px);

  .container {
    max-width: 1400px;
  }

  &__wrapper {
    display: flex;
  }

  &__col {
    &:first-child {
      flex: 0 0 auto;
      width: ac(660px, 400px);
      margin-right: 30px;
    }

    &:last-child {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  &__sticky-box {
    height: 100%;
    margin-bottom: 30px;
  }

  &__sticky {
    position: sticky;
    top: 20px;
  }
}

.blog {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ac(-43px, -20px);

  &--slider {
    margin-top: ac(119px, 35px);
    margin-bottom: 0;
    padding: 0px ac(233px, 0px);
    margin-right: ac(380px, 290px);

    .blog {
      &__slider {
        margin-top: ac(48px, 20px);
        width: 100%;
      }

      &__card {
        width: 100%;
        margin-bottom: 0;
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;

    width: calc(50% - 12px);
    margin-bottom: ac(43px, 20px);

    & > * {
      transition: color 0.3s ease;
    }

    &:hover {
      & > * {
        color: var(--color-blue);
      }
    }
  }

  &__img {
    height: ac(260px, 180px);
  }

  &__date {
    margin-top: ac(31px, 15px);
    font-size: 16px;
    line-height: 131.5%;
  }

  &__title {
    margin-top: ac(19px, 15px);
    font-size: ac(24px, 20px);
    line-height: 121.5%;
  }

  &__descr {
    margin-top: ac(17px, 12px);
    font-size: 18px;
    line-height: 156.5%;
    text-align: justify;
    color: var(--color-liver);
  }
}

.crop-popup {
  padding-right: ac(35px, 0px);

  .coords {
    margin: ac(30px, 15px) 0 ac(-15px, -10px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &>div {
      width: calc(50% - 10px);
      margin-bottom: ac(15px, 10px);
    }

    input,select {
      border: 1px solid var(--color-grey);
      border-radius: 0;
    }
  }

  .btn {
    margin-top: ac(35px, 20px);
    color: var(--color-white);

    &:hover {
      color: var(--color-white);
    }
  }

  #crop_image {
    max-width: calc(100% - ac(70px, 0px));
  }
}

.page404 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  text-align: center;

  background: linear-gradient(
    -45deg,
    var(--color-blue),
    var(--color-light-blue),
    var(--color-sky-blue)
  );
  background-size: 400% 400%;
  animation: gradient 20s ease infinite;

  .container {
    & > * {
      &:not(:last-child) {
        margin-bottom: ac(35px, 20px);
      }
    }

    p {
      font-size: ac(24px, 18px);
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

.popup-info {
  max-width: 698px;
  max-height: 90vh;
  padding: 0;
  bottom: unset;

  &__wrapper {
    position: relative;
  }

  &__close {
    position: absolute;
    right: 16px;
    top: 16px;
    background-color: var(--color-white);
    width: ac(40px, 30px);
    height:  ac(40px, 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s ease;
    cursor: pointer;
    z-index: 10;

    &:hover {
      background-color: var(--color-blue);
      color: var(--color-white);
    }
  }

  &__img {
    position: relative;
    width: 100%;
    height: ac(333px, 150px);

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
    }

    img {
      object-position: 50% 26%;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ac(30px, 20px) ac(70px, 20px) ac(70px, 20px);
  }

  &__logo {
    display: flex;
    width: 151px;
    height: 43px;

    img {
      object-fit: contain;
    }
  }

  &__title {
    margin-top: ac(36px, 25px);
    text-align: center;
  }

  &__descr {
    text-align: center;
    margin-top: ac(20px, 15px);
    line-height: 151.5%;
  }

  .btn {
    margin-top: ac(40px, 30px);
    border-radius: 0px;
    width: 100%;
    max-width: 278px;
    height: 44px;
  }
}

@mixin media 1024 {
  .checkout {
    &__wrapper {
      flex-direction: column;
    }

    &__col {
      &:first-child {
        width: 100%;
        margin-right: 0;
        margin-bottom: 25px;
      }

      &:last-child {
        flex-grow: 1;
      }
    }
  }
}

@mixin media 768 {
  .text-box {
    flex-direction: column;

    &__col {
      max-width: 100%;
      margin-right: 0;
    }

    &__map {
      margin-top: 20px;
      height: 300px;
    }
  }

  .blog {
    &--slider {
      margin-right: 0;
    }
  }
}

@mixin media 568 {
  .crop-popup {
    .coords {
      &>div {
        width: 100%;
      }
    }

    .btn {
      width: 100%;
    }
  }

  .services {
    &__wrapper {
      padding: 15px;
    }

    &__box {
      margin: 0;
    }

    &__text {
      width: 100%;
      text-align: center;
      margin-right: 0;
    }

    &__btn {
      max-width: unset;
      margin-right: 0;
      margin-bottom: 0;
    }

    &__soc-box {
      margin-bottom: 0;
      margin-right: auto;
    }
  }

  .form {
    &--sec {
      h3 {
        text-align: center;
      }
    }

    &--acc {
      label {
        width: 100%;
      }

      h3 {
        text-align: center;
      }

      .btn {
        width: 100%;
      }
    }
  }

  .blog {
    &__card {
      width: 100%;
    }
  }
}

@mixin media 374 {
  .container {
    padding: 0 15px;
  }

  .text-box {
    margin-top: 35px;
  }

  .checkout {
    margin-top: 35px;
  }
}

.maps-container {
  position: relative !important;

  #map {
    width: 100%;
    height: 100%;
  }

  #infowindow-content .title {
    font-weight: bold;
  }

  #infowindow-content {
    display: none;
  }

  #map #infowindow-content {
    display: inline;
  }

  .pac-controls label {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
  }

  #pac-input {
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 300;
    margin-left: 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: 400px;
  }

  #pac-input:focus {
    border-color: #4d90fe;
  }
}

.tooltip-country {
  position: relative;
}

.tooltip-country-wrapper {
  position: absolute;
  right: ac(-15px, -4px);
  top: 50%;
  transform: translate(100%, -50%);
  border-radius: 5px;
  padding: 5px;
  color: var(--color-white);
  background-color: var(--color-black);
  font-size: ac(16px, 14px);
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: .3s ease;

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.btn-loader {

  &.active {
    opacity: .5;
  }

  svg.loader {
    display: none !important;
  }

  &.active {
    pointer-events: none !important;

    span {
      display: none !important;
    }

    svg.loader {
      display: inline !important;
    }
  }
}


.tagify {
  flex-wrap: nowrap;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 8px;
    height: 5px;
    background-color: var(--color-sky-blue);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-blue);
  }

  scrollbar-width: thin;
  scrollbar-color: var(--color-blue) var(--color-sky-blue);

  .tagify__input {
    flex-shrink: 0;
  }
}

.trustpilot-widget {
  &.max-ml-auto {
    @mixin min-media 567 {
      margin-left: auto;
    }
    @mixin media 566 {
      margin-top: 20px;
    }
  }
}