.partners {
    margin-top: 122px;

    .container {
        max-width: 1540px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        font-weight: 500;
        margin-bottom: ac(32px, 20px);
    }

    &__title, &__subtitle {
        text-align: center;
    }

    &__subtitle {
        max-width: 90.5ch;
        margin: 0 auto;
        line-height: ac(24px, 23px);

        p:not(:first-child) {
            margin-top: ac(20px, 10px);
        }
    }

    &__wrap {
        width: calc(100% + ac(20px, 15px));
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: ac(60px, 30px);
        margin-right: ac(-20px, -15px);
        margin-bottom: ac(-20px, -15px);
    }
    
    &__item {
        width: calc(100% / 5 - ac(20px, 15px));
        height: ac(98px, 70px);
        margin-right: ac(20px, 15px);
        margin-bottom: ac(20px, 15px);
        padding: ac(12px, 10px);
        border: 1px solid #F4F5F9;
        background-color: #F4F5F9;
        transition: .3s ease;

        img {
            object-fit: contain;
        }
    }

    &__btn {
        min-width: unset;
        padding-left: ac(45px, 20px);
        padding-right: ac(45px, 20px);
        height: ac(47px, 40px);
        border-radius: 0;
        margin-top: ac(60px, 30px);
    }

    @mixin tab {
        &__item {
            width: calc(100% / 4 - ac(20px, 15px));
        }
    }

    @mixin tab-sm {
        &__item {
            width: calc(100% / 3 - ac(20px, 15px));
        }
    }

    @mixin media 431 {
        &__item {
            width: calc(100% / 2 - ac(20px, 15px));
        }

        &__btn {
            width: 100%;
        }
    }
}