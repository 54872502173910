.banner {
  &--alt {
    .banner__bg {
      &::before {
        display: none;
      }
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 25px;
    width: calc(100% - 50px);
    height: 100%;
    z-index: -1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--gradient-black);
    }
  }

  &__decor {
    padding: ac(54px, 25px);
    width: ac(400px, 250px);
    height: ac(400px, 250px);
    margin: 0 auto;

    img {
      object-fit: contain;
    }
  }

  &__wrapper {
    padding: ac(38px, 25px) 20px;
    text-align: center;
    min-height: ac(400px, 250px);
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title,
  &__descr {
    color: var(--color-white);
    &:last-child{
      margin-bottom: 0;
    }
  }

  &__title {
    margin: 0 ac(400px, 0px) ac(20px, 10px);
  }

  &__descr {
    margin: 0 ac(525px, 0px) ac(29px, 15px);
    font-weight: 500;
    font-size: ac(24px, 20px);
    line-height: 123.5%;
  }

  @mixin media 768 {
    &__bg {
      width: 100%;
      left: 0;
    }

    &__decor {
      padding: 25px 0;
      width: 200px;
    }

    &__wrapper {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @mixin media 568 {
    &--alt {
      .banner__bg {
        img {
          object-position: 42% 50%;
        }
      }
    }
    .banner__decor {
      width: 150px;
    }
  }
}
