.footer {
  margin-top: ac(116px, 50px);
  padding: ac(118px, 50px) 0 ac(73px, 50px);
  background-color: var(--color-blue);
  overflow: hidden;

  &--contact-us {
    margin-top: 0;
  }

  & > * {
    color: var(--color-white);
  }

  &__row,
  &__updated {
    display: flex;
  }

  &__link {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: var(--color-white);
      bottom: 5px;
      left: 0;
      transform-origin: right;
      transform: scaleX(0);
      transition: transform 0.3s ease-in-out;
    }

    &:hover {
      &::before {
        transform-origin: left;
        transform: scaleX(1);
      }
    }
  }

  &__col {
    position: relative;
    display: flex;
    flex: 0 0 auto;
    padding-bottom: ac(85px, 20px);

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 1px;
      height: calc(100% + ac(116px, 50px));
      background-color: var(--color-light-blue);
    }
  }

  &__updated {
    margin-top: ac(-20px, -10px);
    flex-direction: column;
    width: 100%;
    max-width: ac(340px, 250px);
    margin-right: ac(157px, 20px);

    .footer__title-box,
    .footer__form {
      margin-top: ac(20px, 10px);
    }
  }

  &__nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 250px;
    margin-top: ac(7px, 0px);
    margin-right: ac(150px, 20px);

    .footer__link {
      font-size: ac(18px, 16px);
      line-height: ac(38px, 34px);

      &:hover {
        color: var(--white);
      }
    }
  }

  &__name {
    display: flex;
    align-items: center;
    margin-bottom: ac(14px, 10px);
    font-weight: 500;
    font-size: ac(24px, 20px);
    line-height: ac(34px, 30px);
  }

  &__sub-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__input-box {
    position: relative;
  }

  &__input {
    width: 100%;
    padding: 12.5px 60px 12px ac(29px, 10px);
    background-color: var(--color-light-blue);
    border: none;

    &::placeholder {
      color: var(--color-white);
    }
  }

  &__arrow {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    width: 44px;
    height: 44px;
    background-color: var(--color-blue);

    &::before {
      border-color: var(--color-white);
    }

    &:hover {
      background-color: var(--color-white);

      &::before {
        border-color: var(--color-black);
      }
    }
  }

  &__checkbox {
    margin-top: ac(14px, 10px);
    display: flex !important;
  }

  &__title {
    margin-bottom: ac(18px, 10px);
    font-weight: 600;
    font-size: ac(30px, 22px);
    line-height: ac(40px, 32px);
  }

  &__service {
    margin-top: ac(-18px, -10px);
    padding: ac(7px, 0px) ac(130px, 0px) 20px ac(103px, 0px);
    flex-grow: 1;

    .footer__title {
      margin-bottom: ac(11px, 10px);
    }
  }

  &__service,
  &__btn-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__title-box {
    margin-top: ac(18px, 10px);
  }

  &__btn-wrapper {
    margin-top: ac(18px, 10px);
    flex-wrap: wrap;
    margin-right: -15px;
    margin-bottom: -10px;
  }

  &__btn {
    margin-right: 15px;
    margin-bottom: 10px;
  }

  &__score {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: ac(40px, 20px) 0;
    border-top: 1px solid var(--color-light-blue);
    border-bottom: 1px solid var(--color-light-blue);
  }

  &__logo {
    margin-right: ac(28px, 20px);
    width: ac(163px, 110px);
    height: ac(55px, 40px);
    background-color: transparent;
    color: transparent;
    img {
      object-fit: contain;
    }
  }

  &__rating {
    margin-right: auto;
    width: ac(149px, 100px);
    height: ac(34px, 20px);
    background-color: transparent;
    color: transparent;
    img {
      object-fit: contain;
    }
  }

  &__payment {
    height: 31.5px;

    &:not(:first-child) {
      margin-left: ac(20px, 10px);
    }

    img {
      object-fit: contain;
    }
  }

  &__bottom {
    margin-top: ac(40px, 20px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-left: ac(-40px, -20px);

    .footer__link {
      margin-left: ac(40px, 20px);
      font-size: 16px;
      line-height: 36px;
      justify-self: flex-end;
    }
  }

  &__copy {
    margin-left: ac(40px, 20px);
    flex: 0 0 auto;
    font-size: 16px;
    line-height: 36px;
  }

  &__soc-box {
    display: flex;
    align-items: center;
    margin-left: ac(40px, 20px);
  }

  &__soc {
    &:not(:last-child) {
      margin-right: 20px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ac(24px, 20px);

    &:hover {
      color: var(--color-light-blue);
    }
  }

  #bold-credits {
    margin: 0 auto;
    width: 150px;
    transition: ease-in-out 0.25s;

    &::before,
    &::after {
      display: none;
    }

    &:hover {
      transform: scale(1.1);
    }
  }


  @mixin media 1440 {
    &__row {
      flex-direction: column;
    }

    &__col {
      justify-content: space-between;
      border-bottom: 1px solid var(--color-light-blue);

      &::before {
        display: none;
      }
    }

    &__service {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 20px 0;

      .footer__title {
        width: 100%;
      }

      .footer__descr {
        margin-right: 40px;
      }
    }

    &__btn-wrapper {
      flex-direction: row;
    }
  }

  @mixin media 768 {
    &__col {
      flex-wrap: wrap;
      justify-content: space-evenly;
    }

    &__updated {
      flex-direction: row;
      max-width: 100%;
      margin-bottom: 20px;
      margin-right: 0;

      .footer__title-box {
        margin-right: 20px;
      }
    }

    &__input {
      min-width: 260px;
    }

    &__score,
    &__bottom {
      justify-content: center;
    }

    &__logo,
    &__rating {
      width: calc(50% - 20px);
      margin: 0 10px 15px;
    }

    &__logo {
      img {
        object-position: right;
      }
    }

    &__rating {
      img {
        object-position: 15px;
      }
    }

    &__total {
      width: 100%;
      margin-right: 0;
      text-align: center;
      margin-bottom: 15px;
    }

    &__service {
      .footer__descr {
        margin-right: 0px;
      }
    }

    &__sub-menu {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      max-height: 0;
      overflow: hidden;
      transition: 0.3s ease;
      opacity: 0;

      &.active {
        max-height: 500px;
        opacity: 1;
      }
    }

    &__name {
      &.active {
        color: var(--color-light-blue);

        .footer__icon {
          transform: rotate(180deg);

          &::before {
            border-color: var(--color-light-blue);
          }
        }
      }
    }

    &__icon {
      position: relative;
      margin-left: 5px;
      width: 10px;
      height: 10px;
      transition: transform 0.3s ease;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 5px;
        height: 5px;
        border-bottom: 1px solid var(--color-white);
        border-right: 1px solid var(--color-white);
        transform: translate(-50%, -75%) rotate(45deg);
        transition: border-color 0.3s ease;
      }
    }

    &__nav {
      margin-right: 20px;
      user-select: none;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @mixin media 568 {
    &__col {
      padding-bottom: 0;
      flex-direction: column;
      align-items: center;
    }

    &__nav {
      margin-right: 0;
    }

    &__updated {
      flex-direction: column;

      .footer__title-box {
        margin-right: 0;
      }
    }

    &__input {
      width: 100%;
    }

    &__nav {
      margin-bottom: 20px;
      align-items: center;
    }

    &__sub-menu {
      align-items: center;
    }
  }

  @mixin mob-lg {
    &__bottom {
      flex-direction: column-reverse;
      margin-left: 0;
    }

    &__soc-box {
      margin-bottom: 20px;
      margin-left: 0;
    }

    &__copy {
      margin-left: 0;
      margin-top: 15px;
      width: 100%;
      text-align: center;
    }
  }

  @mixin media 374 {
    margin-top: 35px;
    padding-top: 35px;
    padding-bottom: 35px;

    &--contact-us {
      margin-top: 0;
    }

    &__btn {
      width: 100%;
    }

    &__nav {
      margin-right: 0;
    }
  }
}
