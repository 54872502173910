.order {
	border: 1px solid var(--color-grey);
	margin-top: ac(30px, 25px);
	padding: ac(30px, 15px);

	&__title {
		margin-bottom: ac(47px, 20px);
	}

	&__descr {
		font-weight: 500;
		color: var(--color-liver);

		&:first-child {
			margin-left: auto;
		}
	}

	&__product-box {
		width: calc(100% + ac(60px, 30px));
		margin-left: ac(-30px, -15px);
	}

	&__product {
		display: flex;
		align-items: center;
		padding: 20px 30px 30px;
		border-bottom: 1px solid var(--color-grey);

		&:not(:first-child) {
			padding-top: 30px;
		}

		&:first-child {
			.order__price {
				&::before {
					display: block;
				}
			}
		}
	}

	&__img {
		flex: 0 0 auto;
		width: 62px;
		height: 77px;
	}

	&__price, &__name {
		font-weight: 500;
	}

	&__name {
		margin-left: 31px;
		margin-right: 20px;
		max-width: 241px;
		font-size: 18px;
		line-height: 1.1;
	}

	&__price {
		align-self: flex-start;
		position: relative;
		display: flex;
		align-items: center;
		height: 77px;
		margin-left: auto;
		font-size: 20px;

		&::before {
			content: 'Subtotal';
			display: none;
			position: absolute;
			top: -49px;
			right: 0;
			font-size: 18px;
			font-weight: 500;
			color: var(--color-liver);
		}
	}

	&__row {
		display: flex;
		justify-content: space-between;
		margin-top: 29px;
	}

	&__key, &__marked {
		font-weight: 500;
	}
	&__key, &__value {
		font-size: 20px;
	}

	&__key {
		margin-right: 20px;
	}

	&__value {
		text-align: right;
		max-width: 359px;
		color: var(--color-liver);
	}

	&__marked {
		color: var(--color-black);

		&--red {
			color: var(--color-red);
		}
	}

	@mixin media 1024 {
		&__name {
			max-width: 599px;
		}
	}

	@mixin media 568 {
		&__title {
			text-align: center;
		}
		
		&__product {
			flex-wrap: wrap;
			padding: 15px;

			&:not(:first-child) {
				padding-top: 15px;
			}
		}

		&__price {
			margin-top: 20px;
			width: 100%;
			justify-content: flex-end;
			height: auto;

			&::before {
				display: block;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		&__name {
			width: 100%;
			margin: 15px 0 0;
			text-align: center;
		}

		&__img {
			width: 100%;

			img {
				object-fit: contain;
			}
		}
	}

}