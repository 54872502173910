.buy-kit {
    margin-top: ac(31px, 25px);

    &--alt {
        margin-top: ac(122px, 65px);
    }

    .container {
        max-width: 1540px;
    }

    &__title {
        text-align: center;
        font-weight: 500;
    }

    &__wrap {
        margin-top: ac(62px, 30px);
    }

    &__row {
        display: flex;
        padding: ac(30px, 15px);
        &:not(:last-child) {
            margin-bottom: ac(30px, 20px);
        }
        background-color: #F4F5F9;
    }

    &__col {
        flex: 0 0 auto;
        width: 100%;
        max-width: ac(524px, 350px, 900, 1920);
        margin-right: ac(108px, 0px);
    }

    &__icon {
        width: ac(150px, 100px);
        height: ac(150px, 100px);
        margin-bottom: ac(13px, 10px);

        img {
            object-fit: contain;
        }
    }

    &__name {
        margin-bottom: ac(22px, 15px);
    }

    &__descr {
        line-height: 151.5%;
    }

    &__card-box {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-right: ac(-24px, -10px);
    }

    &__product {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% / 2 - ac(24px, 10px));
        margin-right: ac(24px, 10px);

        &>a {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }

        .products__img {
            width: 100%;
            height: ac(275px, 150px);
            margin-bottom: ac(20px, 15px);
            background-color: var(--color-sky-blue);
            img {
                object-fit: cover;
            }
        }

        .products__descr {
            margin-bottom: ac(14px, 10px);
            font-weight: 500;
            line-height: ac(26px, 22px);
        }

        .products__price {
            margin-bottom: ac(29px, 15px);
        }

        object {
            margin-top: auto;
        }

        .btn {
            min-width: unset;
            padding-left: ac(45px, 15px, 900, 1920);
            padding-right: ac(45px, 15px, 900, 1920);
            height: ac(50px, 40px);
            border-radius: 0;
            margin-top: auto;

            span {
                flex: 0 0 auto;
                margin-right: 12px;
            }
        }
    }

    &__bottom {
        margin-top: ac(60px, 30px);

        display: flex;
        justify-content: center;
        align-items: center;
        padding: ac(41px, 15px);

        font-weight: 500;
        font-size: ac(20px, 16px);
        line-height: 131.5%;

        background: #FFFAFA;
        border: 1px solid #CD1415;
    }

    @mixin tab-md {
        &__row {
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &__col {
            max-width: 100%;
            margin-right: 0;
        }

        &__card-box {
            width: calc(100% + ac(15px, 10px, 375, 900));
            justify-content: flex-start;
            margin-right: ac(-15px, -10px, 375, 900);
        }

        &__product {
            margin-top: ac(35px, 15px, 375, 900);
            width: calc(100% / 2 - ac(15px, 10px, 375, 900));
            margin-right: ac(15px, 10px, 375, 900);

            .products__img {
                height: ac(275px, 130px, 431, 900);
            }
        }
    }

    @mixin media 431 {
        &__card-box {
            flex-wrap: wrap;
        }

        &__product {
            width: 100%;

            .products__img {
                height: ac(240px, 200px, 375, 431);
            }

            .btn {
                width: 100%;
            }
        }
    }
}