.products {
  margin-top: ac(91px, 50px);
  padding: ac(72px, 35px) 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-sky-blue);
    z-index: -1;
  }

  &__title {
    margin-right: 10px;
  }

  &__row {
    margin-bottom: ac(32px, 20px);
  }

  &__slide {
    width: 100%;
    max-width: ac(300px, 200px);
    margin-right: ac(40px, 20px);

    &:hover {
      p {
        color: var(--color-blue);
      }
    }
  }

  &__img {
    position: relative;
    height: ac(375px, 200px);
    margin-bottom: ac(30px, 20px);
    background-color: var(--color-white);
    overflow: hidden;

    img {
      transition: 0.3s ease;
      object-fit: contain;
    }
  }

  &__img-slider {
    width: 100% !important;
    height: 100%;
  }

  &__cart {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
    padding: 13px;
    background-color: var(--color-red);
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 10;

    &.disable {
      opacity: .5;

      &::before,
      &::after {
        display: none;
      }

      &:hover {
        cursor: not-allowed;
        background-color: var(--color-red);

        img {
          opacity: 1;
        }
      }
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 2px;
      transform: translate(-50%, -50%);
      background-color: var(--color-white);
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    img {
      transition: opacity 0.3s ease;
    }

    &:hover {
      background-color: var(--color-blue);

      &::before,
      &::after {
        opacity: 1;
      }

      img {
        opacity: 0;
      }
    }
  }

  &__descr,
  &__price {
    transition: color 0.3s ease;
  }

  &__price {
    font-size: ac(18px, 16px);
    line-height: ac(23px, 20px);
  }

  &__descr {
    margin-bottom: 9px;
    font-weight: 600;
    margin-right: ac(40px, 0px);
    font-size: ac(22px, 18px);
    line-height: ac(32px, 28px);
  }

  @mixin media 568 {
    &__slide {
      max-width: 325px;
      margin-right: 15px;
    }

    &__row {
      flex-direction: column;
    }

    &__title {
      align-self: flex-start;
      margin-right: 0;
      margin-bottom: 15px;
    }

    &__btn-box {
      margin-left: auto;
    }
  }

  @mixin media 374 {
    margin-top: 35px;

    &__slide {
      max-width: 290px;
      margin-right: 15px;
    }
  }
}
