.markets {
  margin-top: ac(117px, 50px);

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0 ac(268px, 0px);
    margin-bottom: -12px;
    margin-right: ac(-65px, -20px);
  }

  &__title {
    margin-bottom: ac(53px, 20px);
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    width: calc(50% - ac(65px, 20px));
    margin-right: ac(65px, 20px);
    margin-bottom: 12px;

    &:nth-child(1),
    &:nth-child(2) {
      .markets__descr {
        &::after {
          display: block;
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 0%;
      height: 100%;
      background-color: var(--color-blue);
      transition: width 0.3s ease;
      z-index: -1;
    }

    &:hover {
      .markets__descr {
        color: var(--color-white);
      }

      &::before {
        width: 100%;
      }
    }
  }

  &__img {
    flex: 0 0 auto;
    margin-right: ac(20px, 15px);
    width: ac(114px, 90px);
    height: ac(100px, 80px);
  }

  &__descr {
    position: relative;
    flex-grow: 1;
    height: 100%;
    font-size: ac(24px, 20px);
    line-height: ac(34px, 30px);
    font-weight: 500;
    display: flex;
    align-items: center;
    padding: 11px ac(21px, 15px) ac(4px, 11px);
    transition: color 0.3s ease;

    &::after,
    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: var(--color-grey);
    }

    &::after {
      display: none;
      top: -6px;
    }

    &::before {
      bottom: -6px;
    }
  }

  @mixin media 768 {
    &__item {
      width: 100%;

      &:nth-child(2) {
        .markets__descr {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  @mixin media 374 {
    margin-top: 35px;
  }
}
