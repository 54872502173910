.guide {
    margin-top: ac(60px, 35px);

    &--buy {
        margin-top: ac(121px, 65px);
    }

    .container {
        max-width: 1238px;
    }

    &__wrap {
        display: flex;
        justify-content: space-between;
    }

    &__card {
        width: calc(100% / 2 - 10px);
        padding: ac(30px, 15px);
        background-color: #F4F5F9;
    }

    &__head {
        display: flex;
        justify-content: space-between;
        margin-bottom: ac(25px, 15px);
    }

    &__icon {
        width: ac(160px, 90px);
        height: ac(160px, 90px);

        img {
            object-fit: contain;
        }
    }

    &__img {
        width: ac(204px, 120px);
        height: ac(165px, 90px);
    }

    &__title {
        margin-bottom: ac(20px, 15px);
        line-height: 151.5%;
    }

    &__descr {
        margin-bottom: ac(40px, 25px);
        color: var(--color-liver);
        line-height: 151.5%;
    }

    &__btn {
        min-width: unset;
        padding-left: 45px;
        padding-right: 45px;
        height: ac(47px, 40px);
        border-radius: 0;
        margin-top: auto;
    }

    @mixin tab-md {
        &__wrap {
            flex-direction: column;
        }

        &__card {
            width: 100%;
            &:not(:first-child) {
                margin-top: ac(20px, 15px, 375, 900);
            }
        }
    }

    @mixin media 431 {
        &__btn {
            width: 100%;
        }
    }
}