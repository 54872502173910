.news {
  margin-top: ac(130px, 50px);

  &__title {
    margin-right: 10px;
    max-width: 455px;
    font-weight: 700;
  }

  &__view {
    margin-right: ac(50px, 20px);
    height: ac(54px, 40px);
    border: none;
    background-color: var(--color-sky-blue);
  }

  &__slider {
    width: 100%;
  }

  &__row {
    margin-bottom: ac(26px, 20px);
  }

  &__slide {
    width: 100%;
    max-width: ac(460px, 300px);
    margin-right: ac(40px, 20px);

    &:hover {
      .news__img {
        & > img {
          transform: scale(1.05);
        }
      }

      p {
        color: var(--color-blue);
      }
    }
  }

  &__img {
    position: relative;
    height: ac(260px, 200px);
    margin-bottom: ac(28px, 20px);
    background-color: var(--color-white);
    overflow: hidden;

    img {
      transition: 0.3s ease;
      object-fit: cover;
    }
  }

  &__date {
    font-size: 16px;
    margin-bottom: ac(16px, 10px);
  }

  &__name {
    margin-bottom: 7px;
    font-size: ac(24px, 20px);
    line-height: ac(34px, 30px);
    font-weight: 500;
  }

  &__descr {
    font-size: ac(18px, 16px);
    line-height: ac(30px, 26px);
  }

  @mixin media 568 {
    &__row {
      flex-direction: column;
    }

    &__title {
      margin-right: 0;
      margin-bottom: 15px;
    }

    &__btn-box {
      margin-left: auto;
    }

    &__slide {
      max-width: 325px;
      margin-right: 15px;
    }
  }

  @mixin media 374 {
    margin-top: 35px;

    &__slide {
      max-width: 290px;
      margin-right: 15px;
    }
  }
}
