.deliver {
	display: flex;
	flex-direction: column;
	padding: ac(30px, 15px);
	border: 1px solid var(--color-grey);

	&__checkbox {
		position: relative;
  		display: flex;
  		flex-direction: row !important;
		align-items: center;
		margin-top: 0 !important;
		margin-bottom: 20px;
		cursor: pointer;

		input {
			width: 0;
			height: 0;
			visibility: hidden;
			padding: 0 !important;

			&.active  {
				& ~ span {
					border-color: var(--color-blue);

					&::before {
						opacity: 1;
					}
				}
			}
		}

		span {
			flex: 0 0 auto;
			display: flex;
			align-items: flex-start;
			justify-content: center;
			padding: 3px;
			width: 18px;
			height: 18px;
			border: 2px solid var(--color-black);
			background-color: var(--color-white);
			cursor: pointer;
			transition: 0.3s ease;

			&::before {
				content: "";
				width: 100%;
				height: 80%;
				border-bottom: 2px solid var(--color-blue);
				border-left: 2px solid var(--color-blue);
				transform: rotate(-45deg);
				opacity: 0;
				transition: .3s ease;
			}

			&:hover {
				border-color: var(--color-blue);
			}
		}

		h3 {
			margin-left: 12px;
		}
	}

	&__form {
		margin: 0;
		display: flex;
		flex-direction: column;

		label {
			width: 100%;
		}
	}

	&__hidden {
		display: none;
		visibility: hidden;
		justify-content: space-between;
		flex-wrap: wrap;
		margin-top: -20px;
		margin-right: 0;

		&.active {
			display: flex;
			visibility: visible;
		}
	}

	&__label {
		width: calc(50% - 20px) !important;
	}

	&__textarea {
		height: 71px !important;
	}

	@mixin media 568 {
		&__label {
			width: 100% !important;
		}
	}
}