.descriptions {
  margin-top: ac(120px, 50px);

  &--alt {
    margin-top: ac(92px, 50px);
    .search {
      margin-top: ac(32px, 20px);
    }
    .descriptions__title {
      margin-bottom: ac(30px, 10px);
    }
  }

  &--stock {
    margin-top: 50px;

    .container {
      max-width: 1374px;
    }

    .descriptions {
      &__item {
        display: block;
        margin-top: 48px;

        h3 {
          font-size: ac(26px, 16px, 320, 1920);
        }

        a {
          font-size: ac(18px, 16px);
          line-height: ac(30px, 26px);
        }

        img, p img {
          max-height: unset !important;
          object-position: left;
        }
      }

      &__img-box {
        height: ac(389px, 200px);
      }

      &__img {
        width: calc(50% - ac(15px, 0px));

        &:first-child {
          margin-right: ac(30px, 10px);
        }
      }
    }
  }

  &--blogs {
    .descriptions {
      &__wrapper {
        flex-direction: row-reverse;
      }

      &__content {
        width: 100%;
      }

      &__sidebar {
        flex: 0 0 auto;
        align-self: flex-start;
        width: ac(310px, 270px);
        margin-left: ac(70px, 20px);
      }

      &__tabs {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }

      &__img {
        margin-bottom: ac(30px, 20px);
        width: 100%;
        height: ac(653px, 180px);
      }
    }
  }

  &__preview {
    margin-bottom: ac(30px, 20px);
    height: ac(450px, 250px);

    img {
      object-position: top;
    }
  }

  &__info-box {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: ac(50px, 30px);
  }

  &__info {
    padding: ac(20px, 10px) ac(22px, 10px);
    font-size: ac(18px, 16px);
    line-height: 151.5%;
    border: 1px solid var(--color-grey);

    a {
      font-size: ac(18px, 16px);
      line-height: 151.5%;
      color: var(--color-blue);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__name {
    position: relative;
    width: calc(100% + 20px);
    margin-top: -10px;
    margin-left: -10px;
    padding: 15px 17px;
    margin-bottom: 10px;

    font-size: 18px;
    line-height: 135%;
    font-weight: 500;
    color: var(--color-white) !important;
    background-color: var(--color-blue);

    &:not(:first-child) {
      margin-top: 30px;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 20px;
        transform: translateY(-100%);
        background-color: var(--color-white);
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 10px;
      transform: translateY(100%);
      background-color: var(--color-white);
    }
  }

  &__search {
    position: relative;
    margin: 0 auto 18px;
    width: 100%;
    max-width: 700px;
    border: 1px solid var(--color-grey);
  }

  &__input {
    width: 100%;
    padding: 12px ac(40px, 30px) 12px ac(17px, 15px);
    color: var(--color-black);
    font-size: 16px;

    &::placeholder {
      color: var(--color-light-grey);
    }
  }

  &__loupe {
    position: absolute;
    right: ac(15px, 10px);
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    cursor: pointer;

    img {
      object-fit: contain;
    }
  }

  &__back {
    position: relative;
    padding-left: 34px;

    font-size: ac(20px, 18px);
    color: var(--color-blue);
    font-weight: 500;
    transition: color 0.3s ease;

    &:hover {
      color: var(--color-red);

      .descriptions__arrow--left {
        border-color: var(--color-red);
      }
    }
  }

  &__map {
    margin-top: ac(30px, 20px);
    width: 100%;
    height: ac(426px, 300px);

    iframe {
      width: 100%;
      height: 100%;
    }
  }

  &__wrapper {
    display: flex;
    padding: 0px ac(233px, 0px);
  }

  &__content {
    &.fade-animation {
      animation-duration: 0.8s;
      animation-name: fadeAnimation;
    }

    &--custom {
      p {
        img {
          height: auto !important;
        }
      }
    }
  }

  &__sidebar, &__tabs {
    position: sticky;
    top: 20px;
  }

  &__tabs {
    flex: 0 0 auto;
    align-self: flex-start;
    width: ac(310px, 270px);
    padding: 10px;
    margin-right: ac(70px, 20px);
    background-color: var(--color-sky-blue);

    &.fade-animation {
      animation-duration: 0.8s;
      animation-name: fadeAnimation;
    }

    .simplebar-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .simplebar-vertical {
      width: 8px;
      background-color: var(--color-sky-blue);
    }

    .simplebar-scrollbar {
      background-color: var(--color-black);
    }
  }

  @keyframes fadeAnimation {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &__tab {
    position: relative;
    width: 100%;
    padding: 15px 40px 13px 13px;

    &:not(:first-child) {
      margin-top: 10px;
    }

    font-size: 16px;
    line-height: 135%;
    font-weight: 500;
    background-color: var(--color-white);
    cursor: pointer;
    z-index: 1;

    &.is-open {
      pointer-events: none;
      color: var(--color-white);

      &::before {
        opacity: 1;
      }

      .descriptions__arrow {
        border-color: var(--color-white);
      }
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: var(--color-blue);
      opacity: 0;
      transition: opacity 0.3s ease;
      z-index: -1;
    }

    &:hover {
      color: var(--color-white);

      &::before {
        opacity: 1;
      }

      .descriptions__arrow {
        border-color: var(--color-white);
      }
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    right: 17px;
    width: 8px;
    height: 8px;
    border-bottom: 2px solid var(--color-black);
    border-right: 2px solid var(--color-black);
    transform: translate(-75%, -50%) rotate(-45deg);
    transition: border-color 0.3s ease;

    &--left {
      left: 0;
      right: unset;
      transform: translate(75%, -50%) rotate(135deg);
      border-bottom: 2px solid var(--color-blue);
      border-right: 2px solid var(--color-blue);
    }
  }

  &__item {
    display: none;
    margin: 0;
    border-radius: 0;

    &.is-open {
      display: block;
    }
  }

  &__title {
    margin-bottom: ac(20px, 10px);

    &--sec {
      margin-bottom: ac(50px, 25px);
    }
  }

  &__text {
    margin-bottom: ac(20px, 10px);
    font-size: ac(18px, 16px);
    line-height: 151.5%;
    text-align: justify;
    color: var(--color-liver);

    &--alt {
      text-align: left;
      margin-bottom: ac(18px, 10px);
      font-weight: 500;
    }
  }

  &:not(.descriptions--stock) {
    p {
      img {
        width: 100% !important;
        max-height: 653px !important;

        &:not(:only-child) {
          display: inline-block;
          width: calc(50% - 10px) !important;
          max-height: 277px !important;

          &:first-child {
            margin-right: 20px !important;
          }
        }
      }
    }
  }

  p {
    img {
      object-fit: contain;
    }
  }

  &__img-box {
    display: flex;
    width: 100%;
    height: ac(277px, 200px);
    margin-top: ac(30px, 15px);
    margin-bottom: ac(29px, 15px);
  }

  &__img {
    width: calc(50% - ac(10px, 0px));

    &:first-child {
      margin-right: ac(20px, 10px);
    }
  }

  .beefup {
    border: none;
  }

  .beefup__body {
    padding: 0;

    & > ul,
    & > ol {
      li {
        position: relative;
        padding-left: 26px;
        line-height: 24.5px;
        font-size: 15px;
        font-weight: 400;
        color: var(--color-liver);

        &::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 26px;
          height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    & > ol {
      li {
        counter-increment: num;

        &::before {
          content: counter(num);
        }
      }
    }

    & > ul {
      li {
        &:before {
          content: '•';
        }
      }
    }

    & > a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    & > h3,
    & > h2,
    & > h1,
    & > p,
    & > ol,
    & > ul {
      &:not(:last-child) {
        margin-bottom: ac(20px, 10px);
      }
    }

    & > p {
      font-size: ac(18px, 16px);
      line-height: 151.5%;
      text-align: justify;
      color: var(--color-liver);

      iframe {
        width: 100%;
        height: ac(450px, 250px);
      }

      a {
        font-size: ac(18px, 16px);
        color: var(--color-blue);

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @mixin media 768 {
    &__wrapper {
      flex-direction: column;
    }

    &__sidebar, &__tabs {
      position: relative !important;
      top: 0px;
    }

    &__tabs {
      width: 100%;
      margin-bottom: 20px;

      .simplebar-content {
        flex-flow: row wrap;
        justify-content: space-between;
      }
    }

    &__tab {
      &:first-child {
        margin-left: -10px;
      }
      &:nth-child(2) {
        margin-top: 0px;
      }
      width: calc(100% / 2 - 5px);
    }

    &--blogs {
      .descriptions__wrapper {
        flex-direction: column;
      }

      .descriptions__sidebar {
        margin-left: 0;
        width: 100%;
      }

      .descriptions__tab:nth-child(2) {
        margin-top: 10px;
      }

      .descriptions__name {
        width: 100%;
        margin-left: 0;

        &:first-child {
          margin-top: 0;
        }

        &::before,
        &::after {
          display: none;
        }
      }
    }
  }

  @mixin media 568 {
    &__tabs {
      max-height: 200px;

      .simplebar-content {
        border: 5px solid var(--color-sky-blue);
      }
    }

    &__tab {
      &:nth-child(2) {
        margin-top: 10px;
      }
      width: 100%;
    }
  }

  @mixin media 374 {
    margin-top: 35px;

    &--stock, &--provider {
      .descriptions__item {
        margin-top: 30px;
      }
    }
  }
}
