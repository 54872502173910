.contact-us {
  background-color: var(--color-sky-blue);

  &__wrapper {
    display: flex;
  }

  &__img {
    flex: 0 0 auto;
    width: ac(799px, 300px);
    min-height: 100%;
    margin-right: ac(-65px, 0px);
  }

  &__col {
    max-width: ac(1056px, 419px);
    padding: ac(85px, 20px) ac(147px, 20px) ac(85px, 35px) ac(90px, 20px);
  }

  &__title {
    width: calc(100% + ac(20px, 0px));
    font-size: ac(30px, 22px);
    line-height: ac(40px, 30px);
    font-weight: 600;
  }

  &__subtitle {
    margin-top: ac(30px, 15px);
    font-size: ac(24px, 20px);
    line-height: ac(31px, 26px);
  }

  &__text {
    margin-top: ac(30px, 15px);
    font-size: 15px;
    line-height: 20px;
  }

  &__form {
    padding-top: ac(30px, 15px);
    display: flex;
    flex-wrap: wrap;
  }

  &__label {
    margin-bottom: ac(10px, 5px);
    font-size: 15px;
    line-height: 20px;
    margin-top: ac(20px, 10px);

    &:first-of-type {
      margin-top: ac(30px, 20px);
      margin-bottom: ac(20px, 10px);
    }
  }

  &__btn-box {
    margin-top: ac(27px, 15px);
  }

  &__tel {
    margin-top: ac(27px, 20px);

    &,a {
      font-weight: 500;
      font-size: ac(22px, 15px);
    }
  }

  @mixin media 1024 {
    &__item {
      width: calc(100% - 20px) !important;
    }
  }

  @mixin media 768 {
    &__wrapper {
      flex-direction: column-reverse;
      padding-top: 35px;
    }

    &__col {
      max-width: 100%;
      padding-right: 0;
      padding-left: 0;
    }

    &__item {
      width: calc(50% - 20px) !important;
    }

    &__img {
      width: 100%;
      height: 250px;
      min-height: unset;

      img {
        object-position: 0 32%;
      }
    }
  }

  @mixin media 568 {
    &__item {
      width: calc(100% - 20px) !important;
    }
  }

  @mixin media 374 {
    .btn {
      width: 100%;
    }
  }
}
