.totals {
  margin-top: ac(27px, 20px);

  .container {
    max-width: 1400px;
  }

  &__wrapper {
    display: flex;
    align-items: flex-start;
  }

  &__promo-box {
    width: 418px;
    margin-right: 30px;
    padding: 30px;
    border: 1px solid var(--color-grey);

    .totals__title {
      margin-bottom: 39px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__discounts {
    max-height: ac(280px, 200px);
    overflow: auto;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 8px;
      background-color: var(--color-sky-blue);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--color-blue);
    }

    scrollbar-width: thin;
    scrollbar-color: var(--color-blue) var(--color-sky-blue);

    display: initial;
    list-style: decimal inside;
  }

  &__input {
    margin-bottom: 30px;
    padding: 6px 9px;
    font-size: 15px;
    border: 1px solid var(--color-grey);
  }

  &__btn-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: ac(-30px, -15px);
    margin-bottom: -15px;

    .totals__btn {
      margin-right: ac(30px, 15px);
      margin-bottom: 15px;
    }
  }

  &__btn {
    width: 100%;
    max-width: 200px;
  }

  &__basket-box {
    flex-grow: 1;
    padding: 30px 18px 30px 30px;
    border: 1px solid var(--color-grey);

    .totals__title {
      margin-bottom: 20px;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 29px;

    &--alt {
      width: calc(100% + 18px);
      margin-bottom: 0;
      padding: ac(31px, 20px) ac(35px, 20px) ac(48px, 20px) 0px;
      border-top: 1px solid var(--color-grey);
    }
  }

  &__key {
    margin-right: 20px;
  }

  &__key,
  &__marked {
    font-weight: 500;
  }
  &__key,
  &__value {
    font-size: 20px;
  }

  &__value {
    text-align: right;
    max-width: 359px;
    color: var(--color-liver);
  }

  &__marked {
    color: var(--color-black);

    &--red {
      color: var(--color-red);
    }
  }

  @mixin mob-xl {
    &__wrapper {
      flex-direction: column;
    }

    &__promo-box,
    &__basket-box {
      width: 100%;
    }

    &__promo-box {
      margin-right: 0;
      margin-bottom: 25px;
    }
  }

  @mixin media 568 {
    &__promo-box,
    &__basket-box {
      padding: 15px;
    }

    &__title {
      text-align: center;
    }

    &__btn {
      max-width: unset;
    }
  }
}
