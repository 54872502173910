.side-banner {
	margin-bottom: ac(30px, 25px);
	padding: ac(30px, 15px);
	border: 1px solid var(--color-grey);

	&__text {
		margin-bottom: ac(30px, 20px);
		font-size: ac(20px, 18px);
		line-height: 151.5%;
	}

	&__link {
		font-size: ac(20px, 18px);
		line-height: 151.5%;
		color: var(--color-blue);
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	&__img-box {
		position: relative;
		margin-top: 20px;
		padding: 25px;
		min-height: ac(216px, 150px);
		background-color: var(--color-blue);
		z-index: 1;
	}

	&__title {
		max-width: ac(382px, 230px);
		font-size: ac(24px, 20px);
		line-height: 151.5%;
		color: var(--color-white);

		span {
			font-weight: 500;
		}
	}

	&__img, &__decor {
		position: absolute;
		z-index: -1;
	}

	&__img {
		right: 22px;
    	top: 25px;
		width: 220px;
		height: 121px;
	}

	&__decor {
		bottom: ac(25px, 0px);
		left: 25px;
		width: 89px;
		height: 66px;

		img {
			object-fit: contain;
		}
	}

	@mixin media 1024 {
		&__title {
			max-width: ac(400px, 300px);
		}
	}

	@mixin media 568 {
		&__img-box {
			padding: 15px;
			min-height: 210px;
		}

		&__img {
			top: unset;
			right: 15px;
			bottom: 15px;
			width: 170px;
			height: 100px;
		}

		&__title {
			max-width: unset;
		}

		&__decor {
			left: 0px;
			bottom: 0px;
		}

		&__title {
			max-width: 230px;
		}
	}
}